import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { ZonePicker } from "../Pages/ZonePicker";
import { useLocation } from "react-router-dom";
import { AppNav } from "./AppNav";
import { AddressPicker } from "./AddressPicker";
import { UpperNav } from "./UpperNav";
import { Footer } from "./Footer";
import { MaintenanceWrapper } from "./MaintenanceWrapper";

export const AppWrapper = () => {
  const location = useLocation();
  const zone = useSelector((x) => x.Zone);
  const loggedIn = useSelector((x) => x.LoggedIn);
  const userType = useSelector((x) => x.UserType);
  const dispatch = useDispatch();

  const handleAddressSelect = (e) => {
    if (e) {
      dispatch({
        type: "setZone",
        payload: e.laundry_zone_id || e.laundryZoneId,
      });
      dispatch({
        type: "setAddress",
        payload: {
          address: e.address,
          cords: {
            lng: e.longitude,
            lat: e.latitude,
          },
        },
      });
    }
  };
  return (
    <>
      {/* {zone ? (
        <>
          {!["/login", "/sign-up"].includes(location.pathname) ? (
            <AppNav />
          ) : null}
          <Outlet />
        </>
      ) : loggedIn ? (
        <AddressPicker
          show={true}
          title="Please select your current location..."
          onSelect={handleAddressSelect}
        />
      ) : userType === false ? (
        <ZonePicker />
      ) : (
        <Navigate to="/login" />
      )} */}

      <MaintenanceWrapper>
        <UpperNav />
        <AppNav />
        {zone ? (
          <>
            <Outlet />
          </>
        ) : loggedIn ? (
          <div style={{ minHeight: "100vh" }}>
            <AddressPicker
              show={true}
              title="Please select your current location..."
              onSelect={handleAddressSelect}
            />
          </div>
        ) : userType === false ? (
          <ZonePicker />
        ) : (
          <Navigate to="/login" />
        )}
        <Footer />
      </MaintenanceWrapper>
    </>
  );
};
