import React from "react";
import { useSelector } from "react-redux";
import useCartTotal from "../Hooks/useCartTotal";

export const CartTotal = () => {
  const [price, total] = useCartTotal();

  return (
    <div className="container">
      <div className="row justify-content-center mb-5">
        <hr
          style={{ margin: "22px 0px", borderTopColor: "#e5e5e5", opacity: 1 }}
        />
        <div className="col-lg-8 col-md-10 col-12 d-flex flex-wrap justify-content-between align-items-center px-3">
          <div className="">
            <span
              className="fw-semibold mb-0 d-block app-text-dark"
              style={{
                fontSize: "16px",
                marginBottom: "12px",
              }}
            >
              Total Items
            </span>
            <span
              className="fw-bold mb-0 app-text-primary d-block"
              style={{
                fontSize: "20px",
              }}
            >
              {total} item
              {total === 0 || total > 1 ? "s" : ""}
            </span>
          </div>
          <div className="">
            <span
              className="fw-semibold mb-0 d-block app-text-dark"
              style={{
                fontSize: "16px",
                marginBottom: "12px",
              }}
            >
              Estimate
            </span>
            <span
              className="fw-bold mb-0 app-text-primary d-block"
              style={{
                fontSize: "20px",
              }}
            >
              RS. {price}
            </span>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};
