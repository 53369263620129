import { useFormik } from "formik";
import React from "react";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { resetPassword } from "../../Services/AuthService";

const validationSchema = yup.object({
  password: yup.string().required("This field is required..."),
  confirm_password: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("This field is required..."),
});

export const NewPassword = () => {
  const [submitting, setSubmitting] = React.useState(false);
  const userType = useSelector((x) => x.UserType);
  const loggedIn = useSelector((x) => x.LoggedIn);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (loggedIn) navigate(location.state?.from || "/", { replace: true });
  }, [loggedIn]);

  const formik = useFormik({
    initialValues: {
      password: "",
      confirm_password: "",
      fcm_token: "dfskjfkjshfksdhfdskhfsdfkh",
    },
    validationSchema,
    onSubmit: (v) => {
      console.log(v);
      const payload = {
        phone: sessionStorage.getItem("forgot-pass-phone"),
        reset_token: sessionStorage.getItem("forgot-pass-otp"),
        password: v.password,
        confirm_password: v.confirm_password,
      };
      setSubmitting(true);
      resetPassword(payload).then(
        (res) => {
          setSubmitting(false);
          toast.success(res.message);
          sessionStorage.clear();
          navigate("/login", { replace: true });
        },
        ({ errors }) => {
          setSubmitting(false);
          if (errors?.length > 0) {
            errors.forEach((z) => {
              toast.error(z.message, { autoClose: 5000 });
            });
          }
        }
      );
    },
  });

  return (
    <>
      <div className="container-fluid">
        <div className="row" style={{ minHeight: "100vh" }}>
          <div className="col-md-5 col-12">
            <div className="row">
              <div className="col-12 py-3">
                <img
                  src="/assets/images/logo.png"
                  alt=""
                  style={{ width: "198px", height: "33px" }}
                />
              </div>
              <div
                style={{ height: "calc(100vh - 65px)" }}
                className="col-12 d-flex justify-content-center flex-column align-items-center"
              >
                <form
                  className="col-lg-7 col-md-9 col-12"
                  onSubmit={formik.handleSubmit}
                >
                  <div>
                    <span
                      style={{
                        fontSize: "35px",
                        display: "block",
                        color: "#232323",
                      }}
                      className="fw-bold"
                    >
                      New Password
                    </span>
                    <span
                      style={{
                        fontSize: "18px",
                        display: "block",
                        color: "#969696",
                      }}
                    >
                      Enter and confirm your new password!
                    </span>
                  </div>
                  <Form.Group className="my-3" controlId="password">
                    <Form.Control
                      type="password"
                      name="password"
                      placeholder="Password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={`${
                        Boolean(formik.errors.password) &&
                        formik.touched.password
                          ? "is-invalid"
                          : "mb-3"
                      }`}
                    />
                    {Boolean(formik.errors.password) &&
                    formik.touched.password ? (
                      <Form.Text className="text-danger d-block">
                        {formik.errors.password}
                      </Form.Text>
                    ) : null}
                  </Form.Group>
                  <Form.Group className="mb-4">
                    <Form.Control
                      type="password"
                      id="confirm_password"
                      name="confirm_password"
                      placeholder="Confirm Password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={`${
                        Boolean(formik.errors.confirm_password) &&
                        formik.touched.confirm_password
                          ? "is-invalid"
                          : ""
                      }`}
                    />
                    {Boolean(formik.errors.confirm_password) &&
                    formik.touched.confirm_password ? (
                      <Form.Text className="text-danger">
                        {formik.errors.confirm_password}
                      </Form.Text>
                    ) : null}
                  </Form.Group>
                  <button className="login-btn" type="submit">
                    {submitting ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="me-2"
                      />
                    ) : null}
                    Save
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="col-md-7 d-none d-md-block py-2">
            <img
              src="/assets/images/signup.png"
              alt=""
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                objectPosition: "center",
                maxHeight: "95vh",
                borderRadius: "24px",
                backgroundColor: "#aaa",
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
