import React from "react";
import Modal from "react-bootstrap/Modal";
import GoogleMapComponent from "./GoogleMapComponent";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSelector } from "react-redux";
import Form from "react-bootstrap/Form";
import { toast } from "react-toastify";
import { addAddress, updateAddress } from "../Services/MainService";

const AddressType = ({ value, onChange, selected, label, icon }) => {
  return (
    <div
      className={`card cursor-pointer ${
        selected === value ? "border-primary" : "border-muted"
      }`}
      style={{ width: "fit-content", transition: "300ms" }}
      onClick={() => {
        if (onChange) onChange(value);
      }}
    >
      <div className="card-body d-flex align-items-center py-2">
        <i
          className={`fa-solid fa-${icon} ${
            selected === value ? "text-primary" : "text-muted"
          } me-2`}
          style={{ transition: "300ms" }}
        ></i>
        <p
          className={`mb-0 fw-semibold ${
            selected === value ? "text-primary" : "text-muted"
          }`}
          style={{ transition: "300ms" }}
        >
          {label}
        </p>
      </div>
    </div>
  );
};

const addressTypes = [
  {
    id: "home",
    label: "Home",
    icon: "home",
  },
  {
    id: "office",
    label: "Office",
    icon: "briefcase",
  },
  {
    id: "others",
    label: "Other",
    icon: "location-dot",
  },
];

const validationSchema = yup.object({
  contact_person_name: yup.string().required("This Field is required!"),
  address: yup.string().required("Please select a location from Map!"),
});

export const AddAddress = ({
  show = true,
  onHide = null,
  onAdd = null,
  addy = null,
}) => {
  // const [location, setLocation] = React.useState(null);
  const customer = useSelector((x) => x.Customer);
  const formik = useFormik({
    initialValues: {
      // id: null,
      address_type: "home",
      contact_person_number: "",
      address: "",
      latitude: "",
      longitude: "",
      zoneId: null,
      // method: null,
      contact_person_name: "",
      laundryZoneId: null,
      houseNo: "",
      sector: "",
      building: "",
      streetNo: "",
      // code: null,
    },
    validationSchema,
    onSubmit: (values) => {
      console.log(values);
      const method = values?.id ? updateAddress : addAddress;
      method(values).then(
        (res) => {
          if (onAdd) onAdd(values);
          formik.resetForm();
        },
        (er) => {
          if (er && er.errors && er.errors.length > 0) {
            er.errors.forEach((x) => toast.error(x.message));
          } else
            toast.error(
              `Something went wrong while ${
                values?.id ? "updating" : "adding"
              } new address...`
            );
        }
      );
    },
  });

  React.useEffect(() => {
    if (addy) {
      console.log(addy);
      formik.setValues(addy);
      formik.validateForm();
    }
  }, [addy]);

  React.useEffect(() => {
    if (customer) {
      formik.setFieldValue("contact_person_number", customer.phone);
      formik.setFieldValue(
        "contact_person_name",
        `${customer.f_name} ${customer.l_name}`
      );
    }
  }, [customer, addy, show]);

  const handleLocationChange = (e) => {
    console.log(e);
    formik.setValues((x) => ({
      ...x,
      address: e?.address || null,
      latitude: e?.cords?.lat || null,
      longitude: e?.cords?.lng || null,
      zoneId: e?.zone || null,
      laundryZoneId: e?.zone || null,
    }));
  };

  return (
    <Modal
      show={show}
      // backdrop="static"
      // keyboard={false}
      fullscreen={true}
      centered
      style={{ zIndex: 1056 }}
      onHide={() => {
        if (onHide) onHide(false);
      }}
    >
      <Modal.Body className="pt-0">
        <div className="col-12 text-end pt-2">
          <span
            className=" text-muted cursor-pointer"
            onClick={() => {
              if (onHide) onHide(false);
            }}
          >
            <i className="fa-solid fa-xmark" style={{ fontSize: "20px" }}></i>
          </span>
        </div>
        <div className="container-fluid bg-white">
          <div
            className="row justify-content-center"
            style={{ padding: "20px 0px" }}
          >
            <div className="col-lg-6 col-md-8 col-12 text-center">
              <h1
                className="fw-bold"
                style={{
                  fontSize: "38px",
                  marginBottom: "26px",
                  color: "#656565",
                }}
              >
                We're missing some details
              </h1>
              <span style={{ fontSize: "18px", color: "#656565" }}>
                Please provide complete address details below
              </span>
            </div>
          </div>
        </div>
        <div className="container" style={{ borderTop: "1px solid #E5E7EB" }}>
          <div className="row justify-content-center">
            <div className="col-lg-10 col-md-11" style={{ height: "60vh" }}>
              <GoogleMapComponent
                handleSelect={handleLocationChange}
                noBtn={true}
                cords={addy?.cords}
              />
            </div>
            <div className="col-lg-10 col-md-11 mt-3">
              <div>
                <label className="fw-semibold form-label">
                  YOUR PICKUP / DELIVERY ADDRESS
                </label>
                <div className="card">
                  <div className="card-body">
                    <div className="col-11 ps-0">
                      <p className="m-0 d-flex align-items-center">
                        <i
                          className="fa-solid fa-location-dot me-3"
                          style={{ color: "#32b6e9", fontSize: "24px" }}
                        ></i>
                        {formik.values.address ||
                          "Select Location on map and click pickup location"}
                      </p>

                      {/* <hr className="border-2" /> */}
                    </div>
                  </div>
                </div>
                {Boolean(formik.errors.address) ? (
                  <p className="text-danger fw-semibold ms-1">
                    {formik.errors.address}
                  </p>
                ) : null}
              </div>
              <div className="row">
                <div className="form-group col-md-6 col-12 py-3">
                  <label className="fw-semibold form-label">
                    House No. / Flat No.
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="House No. / Flat No."
                    id="houseNo"
                    name="houseNo"
                    value={formik.values.houseNo}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                <div className="form-group col-md-6 col-12 py-3">
                  <label className="fw-semibold form-label">Sector</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Sector"
                    id="sector"
                    name="sector"
                    value={formik.values.sector}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                <div className="form-group col-md-6 col-12 py-3">
                  {" "}
                  <label className="fw-semibold form-label">
                    Building or Block
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Building or Block"
                    id="building"
                    name="building"
                    value={formik.values.building}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                <div className="form-group col-md-6 col-12 py-3">
                  <label className="fw-semibold form-label">
                    Street Name or No.
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Street Name or No."
                    id="streetNo"
                    name="streetNo"
                    value={formik.values.streetNo}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
              <div className="py-3">
                <label className="form-label fw-semibold mb-3">Label As</label>
                <div className="d-flex flex-wrap align-items-center gap-3">
                  {addressTypes.map((x) => (
                    <AddressType
                      key={x.id}
                      icon={x.icon}
                      label={x.label}
                      value={x.id}
                      selected={formik.values.address_type}
                      onChange={(e) => formik.setFieldValue("address_type", e)}
                    />
                  ))}
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-6 col-12 py-3">
                  <label className="fw-semibold form-label">
                    Contact Person Name
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      Boolean(formik.errors.contact_person_name) &&
                      formik.touched.contact_person_name
                        ? "is-invalid"
                        : ""
                    }`}
                    placeholder="Contact Person Name"
                    id="contact_person_name"
                    name="contact_person_name"
                    value={formik.values.contact_person_name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {Boolean(formik.errors.contact_person_name) &&
                  formik.touched.contact_person_name ? (
                    <span className="text-danger fw-semibold mt-1 d-inline-block ms-1">
                      {formik.errors.contact_person_name}
                    </span>
                  ) : null}
                </div>
                <div className="form-group col-md-6 col-12 py-3">
                  <label className="fw-semibold form-label">
                    Contact Person Number
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Phone"
                    readOnly
                    value={formik.values.contact_person_number}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid bg-white">
          <div
            className="row justify-content-center"
            style={{ padding: "40px 0px" }}
          >
            <div
              className="d-flex col-lg-6 col-md-7 col-12 justify-content-center"
              style={{ columnGap: "20px" }}
            >
              <button
                className="service-btn next"
                // disabled={!formik.isValid}
                onClick={() => {
                  // console.log("hcek");
                  if (!formik.isValid)
                    toast.error("Please fill up the required fields...");
                  formik.handleSubmit();
                }}
              >
                Save Location
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
