import React from "react";
const year = new Date().getFullYear();
export const Footer = () => {
  return (
    <>
      <div
        className="container-fluid app-bg-dark"
        style={{ paddingTop: "30px", paddingBottom: "30px" }}
      >
        <div className="row align-items-center justify-content-md-start justify-content-center row-gap-3">
          <div className="col-md-6 footer-logo-container">
            <img
              src="/assets/images/logo.png"
              style={{ height: "33.19px", width: "198px" }}
              alt=""
            />
          </div>
          <div className="col-md-6 text-center text-md-end text-white">
            <span style={{ fontSize: "15px" }} className="text-center">
              support@jabchaho.com | +92-111-524-246
            </span>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row" style={{ padding: "20px 0px" }}>
          <div className="col-12 footer-text-container text-center text-md-start">
            <span style={{ color: "#A2A2A6", fontSize: "14px" }}>
              © {year}. JabChaho Laundry Services. All Rights Reserved.
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
