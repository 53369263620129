import React from "react";
import Accordion from "react-bootstrap/Accordion";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { clear } from "../Services/Storage";
import NavDropdownMenu from "./NavDropdownMenu";
import { AddressPicker } from "./AddressPicker";

export const AppNav = () => {
  const [name, setName] = React.useState("");
  const loggedIn = useSelector((x) => x.LoggedIn);
  const customer = useSelector((x) => x.Customer);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [menuOpen, setMenuOpen] = React.useState(false);
  const [showAddress, setShowAddress] = React.useState(false);

  React.useEffect(() => {
    if (customer) {
      setName(`${customer?.f_name} ${customer?.l_name}`);
    }
  }, [customer]);
  const Logout = () => {
    dispatch({ type: "setToken", payload: null });
    dispatch({ type: "setOrderType", payload: null });
    dispatch({ type: "setService", payload: null });
    dispatch({ type: "setCategory", payload: null });
    dispatch({ type: "setCart", payload: [] });
    dispatch({ type: "setLoggedIn", payload: false });
    dispatch({ type: "setZone", payload: null });
    dispatch({ type: "setCustomer", payload: null });
    dispatch({ type: "setAddress", payload: null });
    dispatch({ type: "setAddresses", payload: null });
    dispatch({ type: "setOngoingOrders", payload: null });
    dispatch({ type: "setNote", payload: null });
    dispatch({ type: "setPickupDate", payload: null });
    dispatch({ type: "setDeliveryDate", payload: null });
    dispatch({ type: "setCoupon", payload: null });
    clear();
    navigate("/", { replace: true });
  };
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        className="bg-white app-nav-container"
      >
        <Container fluid className="d-flex align-items-center flex-wrap">
          <Navbar.Brand as={Link} to="/" className="p-0">
            <img
              src="/assets/images/logo.png"
              style={{ height: "33.19px", width: "198px" }}
              alt=""
            />
          </Navbar.Brand>
          <Navbar.Collapse
            className="d-lg-flex align-items-center d-none"
            style={{ marginLeft: "68px" }}
          >
            <Nav className="me-auto">
              <Nav.Link
                className="pb-0 px-0 d-flex justify-content-between align-items-center flex-column fw-normal"
                style={{ paddingBottom: "23px", fontSize: "14px" }}
                as={Link}
                to="/"
              >
                Home
                {location.pathname == "/" ? (
                  <span className="app-nav-active"></span>
                ) : null}
              </Nav.Link>
            </Nav>
            <Nav className="ms-auto">
              {loggedIn ? (
                <>
                  <NavDropdownMenu name={name} />
                </>
              ) : (
                <Nav.Link
                  className="pb-0 d-flex align-items-center fw-normal"
                  style={{
                    fontSize: "14px",
                    paddingLeft: "22px",
                    paddingRight: "22px",
                  }}
                  as={Link}
                  to="/login"
                >
                  <img
                    src="/assets/svgs/icons/user-icon.svg"
                    style={{ marginRight: "11px" }}
                    alt=""
                  />
                  Register / Sign In
                </Nav.Link>
              )}
              <Nav.Link
                className="pb-0 px-0 d-flex align-items-center fw-normal"
                style={{
                  fontSize: "14px",
                  paddingLeft: "22px",
                  paddingRight: "22px",
                }}
                as={Link}
                to="/cart"
              >
                <img
                  src="/assets/svgs/icons/bag-icon.svg"
                  style={{ marginRight: "11px" }}
                  alt=""
                />
                My Cart
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            className="ms-auto"
            onClick={toggleMenu}
          />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-false`}
            aria-labelledby={`offcanvasNavbarLabel-expand-false`}
            placement="end"
            className="d-lg-none"
            restoreFocus={false}
            show={menuOpen}
            onHide={toggleMenu}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-false`}>
                <img
                  src="/assets/images/logo.png"
                  style={{ height: "30px" }}
                  alt=""
                />
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <h5 className="fw-semibold">Welcome, {name}</h5>
              <hr />
              <Nav className="justify-content-end flex-grow-1 pe-3">
                <Nav.Link
                  className=" d-flex align-items-center fw-normal"
                  onClick={toggleMenu}
                  style={{
                    fontSize: "14px",
                    padding: "16px 20px",
                  }}
                  as={Link}
                  to="/"
                >
                  <img
                    src="/assets/svgs/icons/bag-icon.svg"
                    style={{ marginRight: "11px" }}
                    alt=""
                  />
                  Home
                </Nav.Link>
                <Nav.Link
                  className=" d-flex align-items-center fw-normal"
                  onClick={toggleMenu}
                  style={{
                    fontSize: "14px",
                    padding: "16px 20px",
                  }}
                  as={Link}
                  to="/cart"
                >
                  <img
                    src="/assets/svgs/icons/bag-icon.svg"
                    style={{ marginRight: "11px" }}
                    alt=""
                  />
                  My Cart
                </Nav.Link>
                <Nav.Link
                  className=" d-flex align-items-center fw-normal"
                  onClick={toggleMenu}
                  style={{
                    fontSize: "14px",
                    padding: "16px 20px",
                  }}
                  as={Link}
                  to="/ongoing-orders"
                >
                  <img
                    src="/assets/svgs/icons/bag-icon-1.svg"
                    style={{ marginRight: "11px" }}
                    alt=""
                  />
                  Ongoing Orders
                </Nav.Link>
                <Nav.Link
                  className=" d-flex align-items-center fw-normal"
                  onClick={toggleMenu}
                  style={{
                    fontSize: "14px",
                    padding: "16px 20px",
                  }}
                  as={Link}
                  to="/orders-history"
                >
                  <img
                    src="/assets/svgs/icons/bag-icon-1.svg"
                    style={{ marginRight: "11px" }}
                    alt=""
                  />
                  Orders History
                </Nav.Link>
                <Nav.Link
                  className=" d-flex align-items-center fw-normal"
                  onClick={toggleMenu}
                  style={{
                    fontSize: "14px",
                    padding: "16px 20px",
                  }}
                  as={Link}
                  to="/notifications"
                >
                  <img
                    src="/assets/svgs/icons/notification.svg"
                    style={{ marginRight: "11px" }}
                    alt=""
                  />
                  Notification
                </Nav.Link>
                <Accordion flush>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <img
                        src="/assets/svgs/icons/user-icon.svg"
                        alt=""
                        style={{ marginRight: "6px" }}
                      />
                      My Profile
                    </Accordion.Header>
                    <Accordion.Body>
                      <Nav.Link
                        className=" d-flex align-items-center fw-normal"
                        onClick={toggleMenu}
                        style={{
                          fontSize: "14px",
                          padding: "16px 20px",
                        }}
                        as={Link}
                        to="/profile"
                      >
                        Profile
                      </Nav.Link>
                      <Nav.Link
                        className=" d-flex align-items-center fw-normal"
                        onClick={() => {
                          toggleMenu();
                          setShowAddress(true);
                        }}
                        style={{
                          fontSize: "14px",
                          padding: "16px 20px",
                        }}
                        as={"span"}
                      >
                        Addresses
                      </Nav.Link>
                      <Nav.Link
                        className=" d-flex align-items-center fw-normal"
                        onClick={toggleMenu}
                        style={{
                          fontSize: "14px",
                          padding: "16px 20px",
                        }}
                        // as={Link}
                        // to="/profile"
                      >
                        Help & Support
                      </Nav.Link>
                      <Nav.Link
                        className=" d-flex align-items-center fw-normal"
                        onClick={toggleMenu}
                        style={{
                          fontSize: "14px",
                          padding: "16px 20px",
                        }}
                        href="https://jabchaho.com/services"
                        // as={Link}
                        // to="/profile"
                      >
                        Service Review
                      </Nav.Link>
                      <Nav.Link
                        className=" d-flex align-items-center fw-normal"
                        onClick={toggleMenu}
                        style={{
                          fontSize: "14px",
                          padding: "16px 20px",
                        }}
                        href="https://jabchaho.com/about-us"
                        // as={Link}
                        // to="/profile"
                      >
                        About Jabchaho
                      </Nav.Link>
                      <Nav.Link
                        className=" d-flex align-items-center fw-normal"
                        onClick={toggleMenu}
                        style={{
                          fontSize: "14px",
                          padding: "16px 20px",
                        }}
                        href="https://jabchaho.com/privacy-policy"
                        // as={Link}
                        // to="/profile"
                      >
                        Privacy Policy
                      </Nav.Link>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                <hr />
                <Nav.Link
                  className=" d-flex align-items-center fw-normal"
                  onClick={() => {
                    toggleMenu();
                    Logout();
                  }}
                  style={{
                    fontSize: "14px",
                    padding: "16px 20px",
                  }}
                  as={Link}
                >
                  <img
                    src="/assets/svgs/icons/logout.svg"
                    style={{ marginRight: "11px" }}
                    alt=""
                  />
                  Logout
                </Nav.Link>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
      <AddressPicker
        show={showAddress}
        closeButton={true}
        onHide={() => setShowAddress(false)}
        title="Below are your addresses!"
        editOnSelect={true}
      />
    </>
  );
};
