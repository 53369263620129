import axios from "axios";
import { Axios, baseUrl } from "./Axios";

export const fetchConfig = async () => {
  return await Axios.get("/v1/config", { responseType: "json" });
};
export const fetchTimeSlot = async (date, type, orderType) => {
  const zone = sessionStorage.getItem("zone");
  return await Axios.get(
    `/time-slots/list/${zone}/${date}/${(type, type)}/${orderType}`
  );
};
export const fetchAddresses = async () => {
  return await Axios.get("/v1/customer/address/list", { responseType: "json" });
};
export const deleteAddress = async (addressId) => {
  return await Axios.post(
    `/v1/customer/address/delete?address_id=${addressId}`,
    {
      _method: "delete",
    },
    { responseType: "json" }
  );
};
export const addAddress = async (payload) => {
  const token = localStorage.getItem("token");
  return axios.post("/api/v1/customer/address/add", payload, {
    responseType: "json",
    baseURL: baseUrl,
    headers: {
      moduleid: "",
      storeType: "laundry",
      Laundryzoneid: payload.laundryZoneId,
      Zoneid: payload.zoneId,
      Authorization: `Bearer ${token}`,
    },
  });
};
export const updateAddress = async (payload) => {
  const token = localStorage.getItem("token");
  return axios.put(`/api/v1/customer/address/update/${payload.id}`, payload, {
    responseType: "json",
    baseURL: baseUrl,
    headers: {
      moduleid: "",
      storeType: "laundry",
      Laundryzoneid: payload.laundryZoneId,
      Zoneid: payload.zoneId,
      Authorization: `Bearer ${token}`,
    },
  });
};
export const fetchCustomerInfo = async () => {
  return await Axios.get("/v1/customer/info", { responseType: "json" });
};
export const updateCustomer = async (payload) => {
  return await Axios.post("/v1/customer/update-profile", payload, {
    responseType: "json",
  });
};
export const fetchNotifications = async ({ limit = 10, offset = 1 }) => {
  return Axios.get(
    `/customer/laundry/get-notifications?limit=${limit}&offset=${offset}`
  );
};
