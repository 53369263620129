import { useFormik } from "formik";
import React from "react";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { login } from "../../Services/AuthService";

const validationSchema = yup.object({
  phone: yup.string().required("This field is required..."),
  password: yup.string().required("This field is required..."),
});

export const Login = () => {
  const [submitting, setSubmitting] = React.useState(false);
  const loggedIn = useSelector((x) => x.LoggedIn);
  const userType = useSelector((x) => x.UserType);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (loggedIn) navigate(location.state?.from || "/", { replace: true });
  }, [loggedIn]);

  const formik = useFormik({
    initialValues: {
      phone: "",
      password: "",
      fcm_token: "dfskjfkjshfksdhfdskhfsdfkh",
    },
    validationSchema,
    onSubmit: (v) => {
      setSubmitting(true);
      login(v).then(
        (res) => {
          setSubmitting(false);
          dispatch({ type: "setToken", payload: res.token });
          dispatch({ type: "setLoggedIn", payload: true });
        },
        ({ errors }) => {
          setSubmitting(false);
          if (errors?.length > 0) {
            errors.forEach((z) => {
              toast.error(z.message, { autoClose: 5000 });
            });
          }
        }
      );
    },
  });

  return (
    <>
      {/* <div className="container">
        <div
          className="row align-items-center justify-content-center"
          style={{ height: "100vh" }}
        >
          <form
            className="col-xl-4 col-lg-6 col-12"
            onSubmit={formik.handleSubmit}
          >
            <Card>
              <Card.Body>
                <div className="d-flex justify-content-center py-2 mb-4">
                  <img src="/assets/images/logo.png" width="60%" alt="" />
                </div>

                <Form.Group className="mb-3" controlId="phone">
                  <Form.Label>Enter Your Mobile Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="phone"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="password">
                  <Form.Label>Enter Your Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </Form.Group>
                <Button
                  variant="warning text-white fw-semibold w-100"
                  disabled={submitting}
                  type="submit"
                >
                  {submitting ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="me-2"
                    />
                  ) : null}
                  Login
                </Button>
                <small className="text-center mt-2 pb-2 d-block">
                  Don't have an account?{" "}
                  <Link className="ms-1 fw-semibold text-primary" to="/sign-up">
                    Sign-up
                  </Link>
                </small>
                {userType != false ? (
                  <>
                    <div className="div position-relative">
                      <hr className="border-2" />
                      <div
                        className="position-absolute w-100 text-center"
                        style={{ top: "-13px" }}
                      >
                        <small className="bg-white px-2 fw-semibold text-muted">
                          OR
                        </small>
                      </div>
                    </div>
                    <small className="text-center mt-2 pb-2 d-block">
                      <span
                        className="ms-1 fw-semibold text-primary text-uppercase cursor-pointer"
                        style={{ textDecoration: "underline" }}
                        onClick={() => {
                          dispatch({ type: "setUserType", payload: false });
                          navigate("/");
                        }}
                      >
                        Continue as Guest
                      </span>
                    </small>
                  </>
                ) : null}
              </Card.Body>
            </Card>
          </form>
        </div>
      </div> */}
      <div className="container-fluid">
        <div className="row" style={{ minHeight: "100vh" }}>
          <div className="col-md-5 col-12">
            <div className="row">
              <div className="col-12 py-3">
                <img
                  src="/assets/images/logo.png"
                  alt=""
                  style={{ width: "198px", height: "33px" }}
                />
              </div>
              <div
                style={{ height: "calc(100vh - 65px)" }}
                className="col-12 d-flex justify-content-center flex-column align-items-center"
              >
                <form
                  className="col-lg-7 col-md-9 col-12"
                  onSubmit={formik.handleSubmit}
                >
                  <div>
                    <span
                      style={{
                        fontSize: "35px",
                        display: "block",
                        color: "#232323",
                      }}
                      className="fw-bold"
                    >
                      Sign in
                    </span>
                    <span
                      style={{
                        fontSize: "18px",
                        display: "block",
                        color: "#969696",
                      }}
                    >
                      Please login to continue to your account.
                    </span>
                  </div>
                  <Form.Group className="mb-3 mt-4" controlId="phone">
                    <Form.Control
                      type="text"
                      name="phone"
                      id="phone"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="+92 3xxxxxxxxx"
                    />
                  </Form.Group>
                  <Form.Group className="mb-4" controlId="password">
                    <Form.Control
                      type="password"
                      name="password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Password"
                    />
                  </Form.Group>
                  <button className="login-btn" type="submit">
                    {submitting ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        className="me-2"
                      />
                    ) : null}
                    Login
                  </button>
                  <small className="text-center mt-2 pb-2 d-block">
                    Don't have an account?{" "}
                    <Link
                      className="ms-1 fw-semibold app-text-primary"
                      to="/sign-up"
                    >
                      Sign-up
                    </Link>
                  </small>
                  {userType != false ? (
                    <>
                      <div className="div position-relative">
                        <hr className="border-2" />
                        <div
                          className="position-absolute w-100 text-center"
                          style={{ top: "-13px" }}
                        >
                          <small
                            className="px-2 fw-semibold text-muted"
                            style={{ backgroundColor: "#f5f5f5" }}
                          >
                            OR
                          </small>
                        </div>
                      </div>
                      <small className="text-center mt-2 pb-2 d-block">
                        <span
                          className="ms-1 fw-semibold app-text-primary text-uppercase cursor-pointer"
                          style={{ textDecoration: "underline" }}
                          onClick={() => {
                            dispatch({ type: "setUserType", payload: false });
                            navigate("/");
                          }}
                        >
                          Continue as Guest
                        </span>
                      </small>
                    </>
                  ) : null}
                </form>
              </div>
            </div>
          </div>
          <div className="col-md-7 d-none d-md-block py-2">
            <img
              src="/assets/images/login.png"
              alt=""
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                objectPosition: "center",
                maxHeight: "95vh",
                borderRadius: "24px",
                backgroundColor: "#aaa",
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
