import React from 'react'

export default function LoaderComponent() {
  return (
        <div className="row justify-content-center align-items-center" style={{ height: "100vh" }}>
            <div className="spinner-border text-warning" role="status" style={{ width: "3rem", height: "3rem" }}>
            <span className="visually-hidden">Loading...</span>
        </div>
    </div>
  )
}
