import { format } from "date-fns";
import React from "react";
import Collapse from "react-bootstrap/Collapse";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useCartTotal from "../Hooks/useCartTotal";
import {
  createOrder,
  fetchDeliveryType,
  fetchServices,
} from "../Services/CartService";
import { TitleHeader } from "../Components/TitleHeader";

export const OrderReview = () => {
  const [loading, setLoading] = React.useState(false);
  const [deliveryType, setDeliveryType] = React.useState(null);
  const [services, setServices] = React.useState([]);
  const [items, setItems] = React.useState([]);
  const [submitting, setSubmitting] = React.useState(false);
  const [paymentMethod, setPaymentMethod] = React.useState("cash_on_delivery"); //digital_payment
  const [amount, count] = useCartTotal();
  const config = useSelector((x) => x.Config);
  const ongoingOrders = useSelector((x) => x.OngoingOrders);
  const orderType = useSelector((x) => x.OrderType);
  const cart = useSelector((x) => x.Cart);
  const pickupDate = useSelector((x) => x.PickupDate);
  const deliveryDate = useSelector((x) => x.DeliveryDate);
  const zone = useSelector((x) => x.Zone);
  const coupon = useSelector((x) => x.Coupon);
  const address = useSelector((x) => x.Addresses);
  const note = useSelector((x) => x.Note);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!cart || cart.length === 0) {
      navigate("/", { replace: true });
    }
  }, []);

  React.useEffect(() => {
    setLoading(true);
    fetchDeliveryType(50, 1).then(
      (res) => {
        setDeliveryType(
          res.find((x) => x.title?.trim()?.toLowerCase() === orderType)
        );
        fetchServices(50, 1).then(
          (res) => {
            setServices(res);
          },
          (err) => console.warn(err, "err while fetching services...")
        );
      },
      (er) => {
        console.warn(er, "delivery types failed");
        // setTypesLoading(false);
      }
    );
  }, []);

  React.useEffect(() => {
    console.log(address);
  }, [address]);

  React.useEffect(() => {
    if (services.length !== 0 && cart.length !== 0) {
      setLoading(true);
      const temp = cart.reduce((result, item) => {
        const key = item.serviceId;
        if (!result[key]) {
          result[key] = {
            services_id: key,
            service: services.find((s) => s.id === key)?.name,
            open: true,
            items: [],
          };
        }
        const { laundry_item_id, price, quantity, bar_code, name, addons } =
          item;
        result[key].items.push({
          laundry_item_id,
          price,
          quantity,
          bar_code,
          name,
          addons,
        });
        return result;
      }, {});
      const tempTemp = Object.values(temp);
      const anotherTemp = [];
      for (let i = 0; i < tempTemp.length; i++) {
        const x = tempTemp[i];
        anotherTemp.push(x);
      }
      setItems(anotherTemp);
      setLoading(false);
    }
  }, [cart, services]);

  const placeOrder = () => {
    if (!loading && items.length !== 0) {
      const maxOrders = config?.laundry_customer_maximum_order;
      if (ongoingOrders?.length < maxOrders) {
        setSubmitting(true);
        const payload = {
          payment_status: "unpaid",
          delivery_date: format(deliveryDate?.date, "yyyy-MM-dd HH:mm:ss.SSS"),
          pickup_date: format(pickupDate?.date, "yyyy-MM-dd HH:mm:ss.SSS"),
          payment_method: paymentMethod,
          platform: 1,
          pickup_coordinates: {
            latitude: address?.pickup?.latitude,
            longitude: address?.pickup?.longitude,
          },
          destination_coordinates: {
            latitude: address?.delivery?.latitude,
            longitude: address?.delivery?.longitude,
          },
          pickup_address: {
            floor: null,
            road: address?.pickup?.streetNo,
            house: address?.pickup?.houseNo,
            address: address?.pickup?.address,
            contact_person_name: address?.pickup?.contact_person_name,
            contact_person_number: address?.pickup?.contact_person_number,
          },
          destination_address: {
            floor: null,
            road: address?.delivery?.streetNo,
            house: address?.delivery?.houseNo,
            address: address?.delivery?.address,
            contact_person_name: address?.delivery?.contact_person_name,
            contact_person_number: address?.delivery?.contact_person_number,
          },
          bar_code: "",
          order_pickup_time_slot_id: pickupDate?.slot?.id,
          order_type_id: deliveryType?.id,
          order_delivery_time_slot_id: deliveryDate?.slot?.id,
          laundry_delivery_type_id: deliveryType?.id,
          service_id: 19,
          category_id: 1,
          delivery_charge: deliveryType?.charge,
          pickup_schedule_at: format(
            pickupDate?.date,
            "yyyy-MM-dd HH:mm:ss.SSS"
          ),
          delivery_schedule_at: format(
            deliveryDate?.date,
            "yyyy-MM-dd HH:mm:ss.SSS"
          ),
          pending: "",
          distance: 10.5,
          note,
          order_pickup_time_slot_date: format(
            pickupDate?.date,
            "yyyy-MM-dd HH:mm:ss.SSS"
          ),
          order_delivery_time_slot_date: format(
            deliveryDate?.date,
            "yyyy-MM-dd HH:mm:ss.SSS"
          ),
          laundryZoneId: zone,
          coupon_code: coupon?.code,
          order_pickup_time_slot: `${pickupDate?.slot?.start_time} - ${pickupDate?.slot?.end_time}`,
          order_delivery_time_slot: `${deliveryDate?.slot?.start_time} - ${deliveryDate?.slot?.end_time}`,
          cart: items,
          tax_amount: config.laundry_tax,
          order_amount:
            amount + amount * (config.laundry_tax / 100) + deliveryType?.charge,
        };
        const afterOrder = (order) => {
          setSubmitting(false);
          console.log(order);
          if (paymentMethod !== "digital_payment") {
            navigate(`/order-placed?orderId=${order?.order_id}`, {
              replace: true,
            });
          }
          dispatch({ type: "setCart", payload: [] });
          dispatch({ type: "setOrderType", payload: null });
          dispatch({ type: "setService", payload: null });
          dispatch({ type: "setCategory", payload: null });
          dispatch({ type: "setDeliveryDate", payload: null });
          dispatch({ type: "setPickupDate", payload: null });
          dispatch({ type: "setCoupon", payload: null });
          dispatch({ type: "setAddresses", payload: null });
          dispatch({ type: "setNote", payload: null });
        };
        createOrder(payload).then(
          (res) => {
            if (paymentMethod === "digital_payment") {
              // payment(res, afterOrder);
              window.location = `https://jabchaho.com/payfast-payment?group_id=${res?.order_id}`;
            }
            afterOrder(res);
          },
          (er) => {
            setSubmitting(false);
            if (er && er.errors && er.errors.length !== 0) {
              console.log(er);
              er.errors.forEach((x) => {
                toast.error(x.message);
              });
            }
          }
        );
      } else
        toast.error(
          `You already have ${ongoingOrders?.length} in queue please try again letter!`
        );
      // const payment = (order, cb) => {
      //   PayFastPayment(order.order_id).then(
      //     (paymentRes) => {
      //       toast.success("Payment Success");
      //       if (cb) {
      //         cb(order);
      //       }
      //     },
      //     (err) => {
      //       toast.error("Something went wrong with payment...");
      //     }
      //   );
      // };
    }
  };

  return (
    <>
      <TitleHeader
        title={"Order Review"}
        subtitle={
          "Double-check your details for a seamless, spotless laundry experience!"
        }
      />
      <div className="container">
        <div className="row justify-content-center py-4">
          <div className="col-xl-6 col-lg-7 col-md-10 col-12">
            {/* Pricing */}
            <div className="card  mb-3">
              <div className="card-body">
                {loading ? (
                  <div className="w-100 text-center py-3">
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <Table borderless responsive className="mb-0">
                    <tbody>
                      {items.map((x, i) => (
                        <>
                          <tr>
                            <td
                              className="fw-bold"
                              style={{ fontSize: "14px" }}
                            >
                              {x.service}
                            </td>
                            {/* <td className="text-end" style={{ fontSize: "14px" }}>
                            <i className="fa-solid fa-chevron-down"></i>
                          </td> */}
                          </tr>
                          <Collapse in={x.open}>
                            <tr>
                              <td colSpan={2}>
                                <Table borderless responsive className="mb-0">
                                  <tbody>
                                    {x.items.map((y) => (
                                      <>
                                        <tr>
                                          <td
                                            className={`py-1 ${
                                              y.addons?.length > 0 ? "pb-0" : ""
                                            }`}
                                          >
                                            {y.quantity} x {y.name}
                                          </td>
                                          <td
                                            className={`text-end py-1 ${
                                              y.addons?.length > 0 ? "pb-0" : ""
                                            }`}
                                          >
                                            RS. {y.price * y.quantity}
                                          </td>
                                        </tr>
                                        {y.addons?.length > 0 ? (
                                          <tr>
                                            <td
                                              colSpan={2}
                                              className="px-2 py-0"
                                            >
                                              <Table borderless responsive>
                                                <tbody>
                                                  {y.addons?.map((z) => (
                                                    <tr>
                                                      <td className="py-0">
                                                        {z.qty} x {z.name}
                                                      </td>
                                                      <td className="py-0 text-end">
                                                        RS. {z.price * z.qty}
                                                      </td>
                                                    </tr>
                                                  ))}
                                                </tbody>
                                              </Table>
                                            </td>
                                          </tr>
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    ))}
                                  </tbody>
                                </Table>
                              </td>
                            </tr>
                          </Collapse>
                        </>
                      ))}
                    </tbody>
                    <tbody style={{ borderTop: "1px solid #aaa" }}>
                      <tr>
                        <td className="fw-semibold">Subtotal</td>
                        <td className="fw-bold text-end">RS. {amount}</td>
                      </tr>
                      {coupon ? (
                        <tr>
                          <td className="fw-semibold">Discount</td>
                          <td className="fw-bold text-end">
                            -RS. {amount * (coupon.discount / 100)}
                          </td>
                        </tr>
                      ) : null}
                      <tr>
                        <td className="fw-semibold">Tax</td>
                        <td className="fw-bold text-end">
                          RS. {amount * (config.laundry_tax / 100)}
                        </td>
                      </tr>
                      <tr>
                        <td className="fw-semibold">Delivery Fee</td>
                        <td className="fw-bold text-end">
                          RS.{" "}
                          {amount >= config.laundry_free_delivery
                            ? 0
                            : deliveryType?.charge}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <hr className="my-0" />
                        </td>
                      </tr>
                      <tr>
                        <td className="fw-semibold">Total</td>
                        <td className="fw-bold text-end">
                          RS.{" "}
                          {amount -
                            (coupon ? amount * (coupon.discount / 100) : 0) +
                            amount * (config.laundry_tax / 100) +
                            (amount >= config.laundry_free_delivery
                              ? 0
                              : deliveryType?.charge)}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                )}
              </div>
            </div>
            {/* Dates */}
            <div className="card  mb-3">
              <div className="card-body">
                <h5 className="fw-bold">Scheduled Dates</h5>

                <div className="app-dates-container border border-gray rounded mt-4">
                  <div className="col-12 col-md-5 px-4">
                    <small
                      style={{ position: "absolute", top: "-12px" }}
                      className="bg-white px-2"
                    >
                      Pickup
                    </small>
                    <div className="d-flex align-items-center py-3 border-r border-gray">
                      <i
                        className="fa-regular fa-calendar-plus me-3"
                        style={{ fontSize: "22px", color: "#666" }}
                      ></i>
                      {pickupDate?.date
                        ? format(pickupDate.date, "eee, do MMM")
                        : "Date"}
                      {pickupDate?.date && pickupDate?.slot ? <br /> : "/"}
                      {pickupDate?.slot
                        ? `${pickupDate?.slot?.start_time} - ${pickupDate?.slot?.end_time}`
                        : "Time"}
                    </div>
                  </div>
                  <div className="col-1 d-none d-md-block border-start border-gray h-100 py-2 border-2">
                    &nbsp;
                  </div>
                  <hr className="w-100 my-1 d-block d-md-none" />
                  <div className="col-12 col-md-5 px-4">
                    <small
                      style={{ position: "absolute" }}
                      className="bg-white px-2 app-dates-delivery-label"
                    >
                      Delivery
                    </small>
                    <div className="d-flex align-items-center py-3">
                      <i
                        className="fa-regular fa-calendar-plus me-3"
                        style={{ fontSize: "22px", color: "#666" }}
                      ></i>
                      {deliveryDate?.date
                        ? format(deliveryDate.date, "eee, do MMM")
                        : "Date"}
                      {deliveryDate?.date && deliveryDate?.slot ? <br /> : "/"}
                      {deliveryDate?.slot
                        ? `${deliveryDate?.slot?.start_time} - ${deliveryDate?.slot?.end_time}`
                        : "Time"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Payment Method */}
            <div className="card  mb-3">
              <div className="card-body">
                <h5 className="fw-bold mb-3">Payment Method</h5>
                <div
                  className="row cursor-pointer align-items-center py-3"
                  onClick={() => setPaymentMethod("cash_on_delivery")}
                >
                  <div className="col-11 d-flex align-items-center h-100">
                    {paymentMethod === "cash_on_delivery" ? (
                      <i
                        class="fa-regular fa-circle-check app-text-primary"
                        style={{ fontSize: "18px" }}
                      ></i>
                    ) : (
                      <i
                        class="fa-regular fa-circle text-muted"
                        style={{ fontSize: "18px" }}
                      ></i>
                    )}
                    <h6 className="ms-2 mb-0">Cash On Delivery</h6>
                  </div>
                </div>
                <hr className="border-2 my-0" />
                <div
                  className="row cursor-pointer align-items-center py-3"
                  onClick={() => setPaymentMethod("digital_payment")}
                >
                  <div className="col-11 d-flex align-items-center h-100">
                    {paymentMethod === "digital_payment" ? (
                      <i
                        class="fa-regular fa-circle-check app-text-primary"
                        style={{ fontSize: "18px" }}
                      ></i>
                    ) : (
                      <i
                        class="fa-regular fa-circle text-muted"
                        style={{ fontSize: "18px" }}
                      ></i>
                    )}
                    <h6 className="ms-2 mb-0">Digital Payment</h6>
                  </div>
                </div>
              </div>
            </div>
            {/* Addresses */}
            <div className="card  mb-3">
              <div className="card-body">
                <h5 className="fw-bold mb-3">Address</h5>
                <div className="row cursor-pointer">
                  <div className="col-1 text-end pe-0">
                    <i
                      className="fa-solid fa-circle-dot"
                      style={{ color: "#32b6e9" }}
                    ></i>
                  </div>
                  <div className="col-11">
                    <h6 className="mb-1">Pickup Address</h6>
                    <p className="m-0">
                      {address?.pickup
                        ? address?.pickup?.address
                        : "Select address"}
                    </p>
                  </div>
                </div>
                <div style={{ margin: "20px 0px" }}></div>
                <div className="row cursor-pointer">
                  <div className="col-1 text-end pe-0">
                    <i
                      className="fa-solid fa-location-dot text-primary"
                      style={{ fontSize: "20px" }}
                    ></i>
                  </div>
                  <div className="col-11">
                    <h6 className="mb-1">Pickup Address</h6>
                    <p className="m-0">
                      {address?.delivery
                        ? address?.delivery?.address
                        : "Select address"}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Note */}
            {note && note !== "" ? (
              <div className="card  mb-3">
                <div className="card-body">
                  <h5 className="fw-bold mb-3">Order Note / Instructions.</h5>
                  <p>{note}</p>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="container-fluid bg-white">
        <div
          className="row justify-content-center"
          style={{ padding: "40px 0px" }}
        >
          <div
            className="d-flex col-lg-6 col-md-7 col-12 justify-content-center"
            style={{ columnGap: "20px" }}
          >
            <button
              className="service-btn next"
              disabled={loading || submitting}
              onClick={() => placeOrder()}
            >
              {submitting ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-2"
                />
              ) : null}
              <span className="fw-semibold">Place Order</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
