import { EncryptStorage } from "encrypt-storage";

export const Storage = new EncryptStorage(
  "8f384a79-6c3a-4fd6-b3e3-934135875584"
);

export const setItem = (key, value) => {
  localStorage.setItem(key, value);
};

export const getItem = (key) => {
  return localStorage.getItem(key);
};

export const clear = () => {
  localStorage.clear();
  sessionStorage.clear();
};

export const removeItem = (key) => {
  localStorage.removeItem(key);
};

export const token = () => {
  localStorage.getItem("token");
};

export const encryptData = (value) => {
  return Storage.encryptValue(value);
};
export const decryptData = (value) => {
  if (value) return Storage.decryptValue(value);
  else return null;
};
