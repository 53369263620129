import React from "react";
import { Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

export const ConfirmationModal = ({
  text = "",
  onYes = null,
  onNo = null,
  show = true,
  yesLoading = false,
  noLoading = false,
}) => {
  return (
    <>
      {show ? (
        <div class="fade modal-backdrop show" style={{ zIndex: 1056 }}></div>
      ) : null}
      <Modal
        show={show}
        backdrop="static"
        keyboard={false}
        fullscreen="md-down"
        centered
        style={{ zIndex: 1056, left: "-10px" }}
      >
        <Modal.Body className="d-flex flex-column align-items-center">
          <img
            src="/assets/images/warning.png"
            width={50}
            alt=""
            className=" mb-3"
          />
          <h6 className="text-center">{text}</h6>
        </Modal.Body>
        <Modal.Footer className="d-block">
          <div className="row">
            <div className="col-md-6">
              <button
                className="btn btn-secondary fw-semibold text-white w-100 m-0"
                disabled={noLoading && yesLoading}
                onClick={() => {
                  if (onNo && !noLoading && !yesLoading) {
                    onNo();
                  }
                }}
              >
                {noLoading ? (
                  <Spinner size="sm" className="text-white me-2" />
                ) : null}
                No
              </button>
            </div>
            <div className="col-md-6">
              <button
                className="btn btn-primary fw-semibold text-white w-100 m-0"
                disabled={noLoading && yesLoading}
                onClick={() => {
                  if (onYes && !noLoading && !yesLoading) {
                    onYes();
                  }
                }}
              >
                {yesLoading ? (
                  <Spinner size="sm" className="text-white me-2" />
                ) : null}
                Yes
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
