import React from "react";
import { TitleHeader } from "../Components/TitleHeader";
import { fetchNotifications } from "../Services/MainService";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";

export const Notifications = () => {
  const [notifications, setNotifications] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    if (notifications.length == 0) {
      setLoading(true);
      fetchNotifications({ limit: 100, offset: 1 })
        .then(
          (res) => setNotifications(res.data),
          (er) =>
            toast.error("Something went wrong while fetching notifications!")
        )
        .finally(() => setLoading(false));
    }
  }, []);

  return (
    <>
      <TitleHeader
        title="Notifications"
        subtitle="Below are your Notifications"
      />
      <div className="container" style={{ minHeight: "55vh" }}>
        <div className="row py-3 justify-content-center">
          {loading ? (
            <div className="col-12 text-center">
              <Spinner variant="primary" />
            </div>
          ) : (
            <div className="col-lg-7 col-md-8 col-12">
              <ul className="list-group list-group-flush">
                {notifications.length > 0 ? (
                  notifications.map((x, i) => (
                    <>
                      {i == 0 ? <hr className="mb-0" /> : null}
                      <Link
                        className="list-group-item my-2 pb-4 cursor-pointer"
                        style={{ backgroundColor: "transparent" }}
                        to={`/order-details?orderId=${x.order_id}`}
                      >
                        <div className="d-flex justify-content-between">
                          <div>
                            <h6 className="mb-0">{x.value}</h6>
                            <small>Order ID: {x.order_id}</small>
                          </div>
                          <small className="text-muted">{x.time}</small>
                        </div>
                      </Link>
                    </>
                  ))
                ) : (
                  <h4 className="text-center text-uppercase text-center">No notification found!</h4>
                )}
              </ul>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
