import { Axios, baseUrl } from "./Axios";

export const fetchOrderDetails = async (orderId) => {
  return await Axios.get(`/customer/laundry/order/details?order_id=${orderId}`);
};
export const fetchCoupon = async (payload) => {
  return await Axios.post("/customer/laundry/order/applycoupon", payload);
};
export const PayFastPayment = async (orderId) => {
  const token = localStorage.getItem("token");
  // return await fetch(`${baseUrl}/payfast-payment?group_id=${orderId}`, {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // });

  return await Axios.get(`/payfast-payment?group_id=${orderId}`, {
    baseURL: baseUrl,
  });
};
export const fetchOrders = async ({ limit, offset }) => {
  return Axios.get(
    `/customer/laundry/order/running-orders?limit=${limit}&offset=${offset}`
  );
};
export const fetchOrdersHistory = async ({ limit, offset }) => {
  return Axios.get(
    `/customer/laundry/order/list?limit=${limit}&offset=${offset}`
  );
};
export const changePayment = async (orderId) => {
  return Axios.post(
    "/customer/laundry/order/payment-method",
    {
      order_id: orderId,
    },
    {
      responseType: "json",
    }
  );
};
export const trackOrder = async (orderId) => {
  return Axios.get(`/customer/laundry/order/track?order_id=${orderId}`, {
    responseType: "json",
  });
};
export const cancelOrder = async (orderId) => {
  return Axios.put(
    "/customer/laundry/order/cancel",
    {
      order_id: orderId,
    },
    {
      responseType: "json",
    }
  );
};
export const fetchWeekDaysOff = async () => {
  return Axios.get("/time-slots/WeekDaysOff", { responseType: "json" });
};
