import { format, isSameDay } from "date-fns";
import React from "react";
import { useSelector } from "react-redux";
import { fetchTimeSlot } from "../Services/MainService";
import Spinner from "react-bootstrap/Spinner";
import { toast } from "react-toastify";

export const DatePicker = ({
  range = [],
  selected = null,
  onChange = null,
  type = 1,
  orderType = null,
  timeSlot = null,
}) => {
  const [p, setP] = React.useState(null);
  const [n, setN] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [slots, setSlots] = React.useState([]);
  const [tempSelected, setTempSelected] = React.useState(selected || range[0]);
  const [tempSlot, setTempSlot] = React.useState(timeSlot);

  React.useEffect(() => {
    console.log(range);
    if (tempSelected) {
      setLoading(true);
      const i = range.findIndex((x) => isSameDay(x, tempSelected));
      console.clear();
      console.log(format(tempSelected, "yyyy-MM-dd"));
      console.log(range);
      console.log(i);
      if (~i && i > 0) setP(range[i - 1]);
      else setP(null);

      if (~i && i < range.length - 1) setN(range[i + 1]);
      else setN(null);

      fetchTimeSlot(format(tempSelected, "yyyy-MM-dd"), type, orderType).then(
        (res) => {
          setLoading(false);
          setSlots(res);
          console.log(res);
          setTempSlot(null);
        },
        (er) => {
          console.warn(er, "error on slot");
          toast.error("Something went wrong while fetching Time Slots.");
        }
      );
    } else {
      setTempSelected(selected || range[0]);
    }
  }, [tempSelected, range]);
  const previous = () => {
    if (p) {
      setTempSelected(p);
    }
  };
  const next = () => {
    if (n) {
      setTempSelected(n);
    }
  };
  return (
    <>
      <div className="d-flex flex-column align-items-center pb-3">
        <h5>{tempSelected && format(tempSelected, "MMMM")}</h5>
        <div className="d-flex justify-content-between align-items-end w-75 pb-3">
          <span
            className="mb-0"
            style={{
              fontSize: "38px",
              color: "#ccc",
              lineHeight: "36px",
              minWidth: "41px",
              minHeight: "1px",
            }}
          >
            {p && format(p, "dd")}
          </span>

          <div className="d-flex align-items-center justify-content-center flex-column">
            <span>{tempSelected && format(tempSelected, "eee")}</span>
            <div className="d-flex align-items-center">
              <span onClick={() => previous()}>
                <i
                  className="fa-solid fa-chevron-left text-primary cursor-pointer"
                  style={{ fontSize: "24px" }}
                ></i>
              </span>
              <span
                className="mb-0 mx-3 text-dark"
                style={{ fontSize: "42px", color: "#ccc", lineHeight: "36px" }}
              >
                {tempSelected && format(tempSelected, "dd")}
              </span>
              <span onClick={() => next()}>
                <i
                  className="fa-solid fa-chevron-right text-primary cursor-pointer"
                  style={{ fontSize: "24px" }}
                ></i>
              </span>
            </div>
          </div>
          <span
            className="mb-0"
            style={{
              fontSize: "38px",
              color: "#ccc",
              lineHeight: "36px",
              minWidth: "41px",
              minHeight: "1px",
            }}
          >
            {n && format(n, "dd")}
          </span>
        </div>
        <h5>Select Time Slot</h5>
        <div className="col-12 text-center py-2">
          {loading ? (
            <Spinner variant="primary" />
          ) : slots?.length !== 0 ? (
            slots.map((x) => (
              <span
                className={`badge border-2 border cursor-pointer d-block border-0 py-2 ${
                  x.id === tempSlot?.id ? "text-dark" : "text-secondary"
                }`}
                style={{ fontSize: "14px", backgroundColor: x.id === tempSlot?.id? "#f3f3f3" : ""}}
                onClick={() => {
                  setTempSlot(x);
                }}
              >
                {x.start_time} - {x.end_time}
              </span>
            ))
          ) : (
            <span>No Time Slots are available for select date.</span>
          )}
        </div>
      </div>
      <button
        className="btn btn-primary w-100 fw-semibold text-dark"
        onClick={() => {
          if (!tempSelected) toast.error("Please select a valid date.");

          if (!tempSlot) toast.error("Please select a time slot.");

          if (tempSelected && tempSlot && onChange) {
            onChange({ date: tempSelected, slot: tempSlot });
          }
        }}
      >
        Save & Continue
      </button>
    </>
  );
};
