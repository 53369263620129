import React, { useState, useRef, useCallback, useEffect } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  Autocomplete,
} from "@react-google-maps/api";
import LoaderComponent from "./LoaderComponent";
import { checkZoneisExist } from "../Services/MapService";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { updateCustomer } from "../Services/MainService";

const libraries = ["places"]; // Load the places library for autocomplete

const mapContainerStyle = {
  height: "100%", // Adjust height according to your design
  width: "100%",
};

const defaultCenter = {
  lat: 24.8607, // Default latitude (e.g., Karachi)
  lng: 67.0011, // Default longitude (e.g., Karachi)
};

const GoogleMapComponent = ({
  handleSelect,
  noBtn = false,
  cords = {
    lat: 24.8607, // Default latitude (e.g., Karachi)
    lng: 67.0011, // Default longitude (e.g., Karachi)
  },
}) => {
  let navigate = useNavigate();
  const [map, setMap] = useState(null);
  const [loader, setLoader] = useState(false);
  const [markerPosition, setMarkerPosition] = useState(cords || defaultCenter);
  const [address, setAddress] = useState("");
  const autocompleteRef = useRef(null);
  const inputRef = useRef(null);
  const [zones, setZones] = useState([]);
  const [error, setError] = useState([]); // State to handle any errors
  const [firstTime, setFirstTime] = React.useState(true);
  const SecretKey = process.env.REACT_APP_MAP_API_KEY;

  const handleLocationUpdate = (newPosition, source) => {
    setMarkerPosition(newPosition);
    if (map) {
      map.panTo(newPosition);
      map.setZoom(14);
    }
    updateAddress(newPosition, (ad) =>
      handlePickupLocation(newPosition, source, ad)
    );
  };

  const onPlaceChanged = () => {
    const place = autocompleteRef.current.getPlace();
    if (place.geometry) {
      const location = place.geometry.location;
      const newMarkerPosition = {
        lat: location.lat(),
        lng: location.lng(),
      };
      setAddress(place.formatted_address);
      handleLocationUpdate(newMarkerPosition, "Selected");
    }
  };

  const onGeolocationSuccess = (position) => {
    const { latitude, longitude } = position.coords;
    const currentLocation = { lat: latitude, lng: longitude };
    handleLocationUpdate(currentLocation, "Current");
  };

  const onGeolocationError = (error) => {
    console.error("Error getting current location:", error);
  };

  const onLoad = useCallback((mapInstance) => {
    setMap(mapInstance);
    // Get the user's current location
    if (cords) {
      handleLocationUpdate(cords, "danger");
    } else if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        onGeolocationSuccess,
        onGeolocationError
      );
    }
  }, []);

  const updateAddress = (location, cb) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location }, (results, status) => {
      if (status === "OK" && results[0]) {
        setAddress(results[0].formatted_address);
        if (cb) cb(results[0].formatted_address);
      } else {
        console.error("Geocode error:", status);
      }
    });
  };

  // React.useEffect(() => {}, [cords]);

  const handlePickupLocation = async (location, source, ad) => {
    setLoader(true);
    try {
      setError([]);
      const response = await checkZoneisExist(location.lat, location.lng);
      const laundryZoneIdString = response.laundry_zone_id;
      const laundryZoneIdArray = laundryZoneIdString
        .substring(1, laundryZoneIdString.length - 1)
        .split(",");
      setZones(laundryZoneIdArray); // Assuming the response has a 'data' field
      if (noBtn && handleSelect) {
        handleSelect({
          zone: laundryZoneIdArray[0],
          address: ad,
          cords: location,
        });
      }
    } catch (err) {
      if (firstTime) setFirstTime(false);
      else toast.error("Service not available in this area..");

      if (noBtn && handleSelect) handleSelect(null);

      console.log(err);
      setError(err);
    } finally {
      setLoader(false);
    }
  };

  const savePickupZone = (zones) => {
    // console.log(zones);
    // console.log(address);
    // console.log(markerPosition);
    if (handleSelect) {
      handleSelect({ zone: zones[0], address, cords: markerPosition });
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = address;
    }
  }, [address]);

  return (
    <LoadScript googleMapsApiKey={SecretKey} libraries={libraries}>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={markerPosition}
        zoom={14}
        onLoad={onLoad}
        onClick={(e) => {
          const location = { lng: e.latLng.lng(), lat: e.latLng.lat() };
          handleLocationUpdate(location, "test");
          setMarkerPosition(location);
        }}
      >
        <Autocomplete
          onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
          onPlaceChanged={onPlaceChanged}
        >
          <input
            ref={inputRef}
            type="text"
            placeholder="Search for a place"
            className="form-control"
            style={{
              boxSizing: "border-box",
              border: "1px solid transparent",
              width: "95%",
              // height: "50px",
              padding: "12px",
              borderRadius: "3px",
              boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
              fontSize: "14px",
              outline: "none",
              textOverflow: "ellipses",
              position: "absolute",
              left: "50%",
              transform: "translate(-50%)",
              // marginLeft: "-120px",
              top: "5px",
            }}
          />
        </Autocomplete>
        <Marker position={markerPosition} />
        {/* Pickup Location Button */}
        {!noBtn ? (
          error.length === 0 && zones.length !== 0 && !loader ? (
            <button
              onClick={() => savePickupZone(zones, "Button")}
              style={{
                position: "absolute",
                bottom: "20px",
                left: "50%",
                transform: "translateX(-50%)",
                zIndex: "1",
                width: "90%",
              }}
              className="btn btn-primary fw-semibold text-white"
            >
              Pickup Location
            </button>
          ) : (
            <div
              style={{
                position: "absolute",
                bottom: "20px",
                left: "50%",
                transform: "translateX(-50%)",
                zIndex: "1",
                backgroundColor: "#ddd",
                border: "1px solid #ccc",
                borderRadius: "4px",
                cursor: "pointer",
                width: "90%",
              }}
              className="fw-semibold py-2 px-3 text-center"
            >
              Service not available in your area
            </div>
          )
        ) : null}
        {/* Render loader if loading */}
        {loader && (
          <div
            style={{
              position: "absolute",
              bottom: "60px",
              left: "50%",
              transform: "translateX(-50%)",
            }}
          >
            <LoaderComponent />
          </div>
        )}
        {/* Render error message if there are errors */}

        {error && error.data && (
          <div
            style={{
              position: "absolute",
              bottom: "20px",
              left: "50%",
              transform: "translateX(-50%)",
              zIndex: "1",
              padding: "10px",
              backgroundColor: "#ffffff",
              border: "1px solid #ccc",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            {error.data.errors[0].message}
          </div>
        )}
      </GoogleMap>
    </LoadScript>
  );
};

export default GoogleMapComponent;
