import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { changePayment } from "../Services/OrderService";
import { Spinner } from "react-bootstrap";

export const PaymentFail = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    console.log(params.get("id"));
  }, [params]);

  const change = () => {
    if (!loading) {
      setLoading(true);
      const id = params.get("id");
      changePayment(id).then(
        (res) => {
          setLoading(false);
          toast.success(res.message);
          navigate(`/order-placed?orderId=${id}`, { replace: true });
        },
        (er) => {
          setLoading(false);
          if (er && er.errors && er.errors.length)
            er.errors.forEach((x) => toast.error(x.message));
          else
            toast.error(
              "Something went wrong while changing payment method..."
            );
        }
      );
    }
  };

  return (
    <Modal
      show={true}
      // onHide={() => setIsOpen(false)}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Body>
        <div className="px-3">
          <div className="d-flex justify-content-center">
            <img src="/assets/images/warning.png" width={50} alt="" />
          </div>
          <h4 className="fw-bold text-center my-3">
            Do you agree with this order failure?
          </h4>
          <h5 className="fw-normal text-center mb-4">
            If you do not continue with your order payment system then your
            order will be failed . You can switch your order to Cash On Delivery
            now.
          </h5>
        </div>
        <Button
          variant="primary"
          className="w-100 mt-2 fw-semibold text-white"
          onClick={() => change()}
          disabled={loading}
        >
          {loading ? <Spinner size="sm" className="text-white me-2" /> : null}
          Switch to Cash on Delivery
        </Button>
        <Button
          variant="outline-danger"
          className="w-100 mt-2 fw-semibold"
          onClick={() => navigate("/", { replace: true })}
          disabled={loading}
        >
          Continue with Order Fail
        </Button>
      </Modal.Body>
    </Modal>
  );
};
