import { Axios } from "./Axios/index";

export const login = async (payload) => {
  return await Axios.post("/v1/auth/login", payload, { responseType: "json" });
};
export const register = async (payload) => {
  return await Axios.post("/v1/auth/register", payload, {
    responseType: "json",
  });
};
export const forgotPassword = async (payload) => {
  return await Axios.post("/v1/auth/forgot-password", payload, {
    responseType: "json",
  });
};
export const verifyToken = async (payload) => {
  return await Axios.post("/v1/auth/verify-token", payload, {
    responseType: "json",
  });
};
export const resetPassword = async (payload) => {
  return await Axios.put("/v1/auth/reset-password", payload, {
    responseType: "json",
  });
};
