import React from "react";
import { useSelector } from "react-redux";

const useCartTotal = () => {
  const cart = useSelector((x) => x.Cart);
  const [amount, setAmount] = React.useState(0);
  const [items, setItems] = React.useState(0);

  React.useEffect(() => {
    let pp = 0;
    let tQty = 0;
    for (let i = 0; i < cart.length; i++) {
      const x = { ...cart[i] };
      const xQ =
        typeof x.quantity == "string" ? parseInt(x.quantity) : x.quantity;
      tQty += xQ;
      const xP = typeof x.price == "string" ? parseFloat(x.price) : x.price;
      pp += xQ * xP;
      for (let j = 0; j < x.addons.length; j++) {
        const y = { ...x.addons[j] };
        const yQ = typeof y.qty == "string" ? parseInt(y.qty) : y.qty;
        const yP = typeof y.price == "string" ? parseFloat(y.price) : y.price;
        pp += yQ * yP;
      }
    }
    setAmount(pp);
    setItems(tQty);
  }, [cart]);

  return [amount, items];
};

export default useCartTotal;
