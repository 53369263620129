import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { verifyToken } from "../../Services/AuthService";

export const PasswordOTP = () => {
  const navigate = useNavigate();
  const [values, setValues] = React.useState({
    1: "",
    2: "",
    3: "",
    4: "",
  });
  const handleChange = (e) => {
    const id = e?.["target"]?.["id"];
    const v = e?.["target"]?.["value"];
    const b = id?.["split"]("-");
    const index = b[b?.["length"] - 1];

    const nv = { ...values };
    nv[index] = v;
    setValues((values) => {
      return nv;
    });
    if (index === "4") {
      e?.["target"]?.["blur"]();
      finalHandle(nv);
    } else {
      b[b?.["length"] - 1] = parseInt(index) + 1;
      const nId = b?.["join"]("-");
      document?.["getElementById"](nId)?.["focus"]();
    }
  };
  const handlePaste = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = clipboardData.getData("Text");
    let xy = false;
    if (pastedData.length === 4) {
      const vs = pastedData.split("");
      for (let i = 0; i < vs.length; i++) {
        const x = vs[i];
        const rx = /^[0-9]$/gm;
        if (!rx.test(x)) {
          xy = true;
          return;
        }
      }
      if (!xy) {
        const tx = {};
        vs.forEach((vvv, i) => {
          tx[i + 1] = vvv;
        });
        setValues(tx);
        finalHandle(tx);
      }
    } else xy = true;

    if (xy) {
      toast.error("Invalid OTP");
    }
  };
  const finalHandle = (vv) => {
    console.log(vv);
    const otp = Object.values(vv).join("");
    console.log(otp);
    const ex = /^[0-9][0-9][0-9][0-9]$/gm;
    const phone = sessionStorage.getItem("forgot-pass-phone");
    if (phone) {
      if (ex.test(otp)) {
        verifyToken({ phone, reset_token: otp }).then(
          () => {
            sessionStorage.setItem("forgot-pass-otp", otp);
            navigate("/reset-password", { replace: true });
          },
          (er) => {
            if (er && er.errors && er.errors.length !== 0) {
              console.log(er);
              er.errors.forEach((x) => {
                toast.error(x.message);
              });
            } else toast.error("Something went wrong...");
          }
        );
      } else toast.error("Please enter a valid OTP!");
    }
  };

  return (
    <div className="container-fluid">
      <div
        className="row justify-content-center"
        style={{ minHeight: "100vh" }}
      >
        <div className="col-lg-5 col-md-7 col-12">
          <div className="row">
            <div className="col-12 py-3">
              <img
                src="/assets/images/logo.png"
                alt=""
                style={{ width: "198px", height: "33px" }}
              />
            </div>
            <div
              style={{ height: "calc(100vh - 65px)" }}
              className="col-12 d-flex justify-content-center flex-column align-items-center"
            >
              <form className="col-lg-7 col-12">
                <div>
                  <span
                    style={{
                      fontSize: "35px",
                      display: "block",
                      color: "#232323",
                    }}
                    className="fw-bold text-lg-start text-center"
                  >
                    OTP Verification
                  </span>
                  <span
                    style={{
                      fontSize: "18px",
                      display: "block",
                      color: "#969696",
                    }}
                    className="text-lg-start text-center"
                  >
                    Enter the verification code we just sent to your number
                  </span>
                </div>
                <div className="otp-input-container justify-content-lg-start justify-content-center">
                  <input
                    type="number"
                    className="otp-input"
                    id="otp-input-1"
                    onPaste={handlePaste}
                    onChange={handleChange}
                    value={values[1]}
                    autoFocus
                  />
                  <input
                    type="number"
                    className="otp-input"
                    id="otp-input-2"
                    onPaste={handlePaste}
                    onChange={handleChange}
                    value={values[2]}
                  />
                  <input
                    type="number"
                    className="otp-input"
                    id="otp-input-3"
                    onPaste={handlePaste}
                    onChange={handleChange}
                    value={values[3]}
                  />
                  <input
                    type="number"
                    className="otp-input"
                    id="otp-input-4"
                    onPaste={handlePaste}
                    onChange={handleChange}
                    value={values[4]}
                  />
                </div>

                {/* <small className="text-center mt-2 pb-2 d-block">
                  Din't receive code?{" "}
                  <span className="ms-1 fw-semibold text-primary">Resend</span>
                </small> */}
              </form>
            </div>
          </div>
        </div>
        <div className="col-lg-7 d-none d-lg-block py-2">
          <img
            // src="/assets/images/login.png"
            alt=""
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "center",
              maxHeight: "95vh",
              borderRadius: "24px",
              backgroundColor: "#aaa",
            }}
          />
        </div>
      </div>
    </div>
  );
};
