import { combineReducers } from "redux";
import {
  Address,
  Addresses,
  Cart,
  Category,
  Config,
  Coupon,
  Customer,
  DeliveryDate,
  LoggedIn,
  Note,
  OngoingOrders,
  OrderType,
  PickupDate,
  Service,
  Token,
  UserType,
  Zone,
} from "./Reducers";

export const rootReducer = combineReducers({
  LoggedIn: LoggedIn,
  Token: Token,
  OrderType: OrderType,
  Service: Service,
  Category: Category,
  Cart: Cart,
  Config: Config,
  Customer: Customer,
  Zone: Zone,
  PickupDate: PickupDate,
  DeliveryDate: DeliveryDate,
  Coupon: Coupon,
  // Map: Map,
  Address: Address,
  Addresses: Addresses,
  UserType: UserType,
  Note: Note,
  OngoingOrders: OngoingOrders,
});
