import { getItem, removeItem, setItem } from "../Storage";

const loggedIn = Boolean(getItem("token"));
const _token = getItem("token");
const _userType = getItem("userType");
const _orderType = getItem("orderType");
const _service = getItem("service");
const _category = getItem("category");
const _cart = getItem("cart") ? JSON.parse(getItem("cart")) : [];
const _config = getItem("config") ? JSON.parse(getItem("config")) : null;
const _customer = getItem("customer") ? JSON.parse(getItem("customer")) : null;
const _zone = sessionStorage.getItem("zone");
const _note = sessionStorage.getItem("note");
const _pickupDate = getItem("pickupDate")
  ? JSON.parse(getItem("pickupDate"))
  : null;
const _deliveryDate = getItem("deliveryDate")
  ? JSON.parse(getItem("deliveryDate"))
  : null;
const _coupon = getItem("coupon") ? JSON.parse(getItem("coupon")) : null;
const _address = getItem("address") ? JSON.parse(getItem("address")) : null;
const _addresses = getItem("addresses")
  ? JSON.parse(getItem("addresses"))
  : null;
const _ongoingOrders = getItem("ongoingOrders")
  ? JSON.parse(getItem("ongoingOrders"))
  : [];

export const LoggedIn = (state = loggedIn, action) => {
  switch (action.type) {
    case "setLoggedIn":
      return (state = action.payload);
    default:
      return state;
  }
};
export const Token = (state = _token, action) => {
  switch (action.type) {
    case "setToken":
      if (action.payload !== null || action.payload !== undefined)
        setItem("token", action.payload);
      else removeItem("token");
      return (state = action.payload);
    default:
      return state;
  }
};
export const OrderType = (state = _orderType, action) => {
  switch (action.type) {
    case "setOrderType":
      if (action.payload !== null || action.payload !== undefined)
        setItem("orderType", action.payload);
      else removeItem("orderType");
      return (state = action.payload);
    default:
      return state;
  }
};
export const UserType = (state = _userType, action) => {
  switch (action.type) {
    case "setUserType":
      if (action.payload !== null || action.payload !== undefined)
        setItem("userType", action.payload);
      else removeItem("userType");
      return (state = action.payload);
    default:
      return state;
  }
};
export const Service = (state = _service, action) => {
  switch (action.type) {
    case "setService":
      if (action.payload !== null || action.payload !== undefined)
        setItem("service", action.payload);
      else removeItem("service");
      return (state = action.payload);
    default:
      return state;
  }
};
export const Category = (state = _category, action) => {
  switch (action.type) {
    case "setCategory":
      if (action.payload !== null || action.payload !== undefined)
        setItem("category", action.payload);
      else removeItem("category");
      return (state = action.payload);
    default:
      return state;
  }
};
export const Cart = (state = _cart, action) => {
  switch (action.type) {
    case "setCart":
      if (action.payload !== null || action.payload !== undefined)
        setItem("cart", JSON.stringify(action.payload));
      else removeItem("cart");
      return (state = action.payload);
    default:
      return state;
  }
};
export const Config = (state = _config, action) => {
  switch (action.type) {
    case "setConfig":
      if (action.payload !== null || action.payload !== undefined)
        setItem("config", JSON.stringify(action.payload));
      else removeItem("config");
      return (state = action.payload);
    default:
      return state;
  }
};
export const Customer = (state = _customer, action) => {
  switch (action.type) {
    case "setCustomer":
      if (action.payload !== null || action.payload !== undefined)
        setItem("customer", JSON.stringify(action.payload));
      else removeItem("customer");
      return (state = action.payload);
    default:
      return state;
  }
};
export const Zone = (state = _zone, action) => {
  switch (action.type) {
    case "setZone":
      if (action.payload !== null && action.payload !== undefined)
        sessionStorage.setItem("zone", action.payload);
      else sessionStorage.removeItem("zone");
      return (state = action.payload);
    default:
      return state;
  }
};
export const PickupDate = (state = _pickupDate, action) => {
  switch (action.type) {
    case "setPickupDate":
      if (action.payload !== null || action.payload !== undefined)
        setItem("pickupDate", JSON.stringify(action.payload));
      else removeItem("pickupDate");
      return (state = action.payload);
    default:
      return state;
  }
};
export const DeliveryDate = (state = _deliveryDate, action) => {
  switch (action.type) {
    case "setDeliveryDate":
      if (action.payload !== null || action.payload !== undefined)
        setItem("deliveryDate", JSON.stringify(action.payload));
      else removeItem("deliveryDate");
      return (state = action.payload);
    default:
      return state;
  }
};
export const Coupon = (state = _coupon, action) => {
  switch (action.type) {
    case "setCoupon":
      if (action.payload !== null || action.payload !== undefined)
        setItem("coupon", JSON.stringify(action.payload));
      else removeItem("coupon");
      return (state = action.payload);
    default:
      return state;
  }
};
export const Address = (state = _address, action) => {
  switch (action.type) {
    case "setAddress":
      if (action.payload !== null || action.payload !== undefined)
        setItem("address", JSON.stringify(action.payload));
      else removeItem("address");
      return (state = action.payload);
    default:
      return state;
  }
};
export const Addresses = (state = _addresses, action) => {
  switch (action.type) {
    case "setAddresses":
      if (action.payload !== null || action.payload !== undefined)
        setItem("addresses", JSON.stringify(action.payload));
      else removeItem("addresses");
      return (state = action.payload);
    default:
      return state;
  }
};
export const OngoingOrders = (state = _ongoingOrders, action) => {
  switch (action.type) {
    case "setOngoingOrders":
      if (action.payload !== null || action.payload !== undefined)
        setItem("ongoingOrders", JSON.stringify(action.payload));
      else removeItem("ongoingOrders");
      return (state = action.payload);
    default:
      return state;
  }
};
export const Note = (state = _note, action) => {
  switch (action.type) {
    case "setNote":
      if (action.payload !== null || action.payload !== undefined)
        setItem("note", action.payload);
      else removeItem("note");
      return (state = action.payload);
    default:
      return state;
  }
};

// export const Map = (state = _map, action) => {
//   switch (action.type) {
//     case "setZone":
//       setItem("zone", action.payload);
//       return (state = action.payload);
//     default:
//       return state;
//   }
// };
