import React from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

export const ItemCounter = ({ value = 0, onChange }) => {
  const cart = useSelector((x) => x.Cart);
  const config = useSelector((x) => x.Config);

  const handleDecrement = () => {
    if (value > 0) {
      const newCount = value - 1;
      if (onChange) onChange(newCount);
    }
  };

  const handleIncrement = () => {
    const maxQty = config?.["laundry_number_of_items_per_order"];
    let qty = 0;
    cart?.forEach((x) => {
      qty += x.quantity;
    });
    console.log(qty, maxQty);
    if (qty < maxQty) {
      const newCount = value + 1;
      if (onChange) onChange(newCount);
    } else
      toast.error(
        `You can order only ${maxQty} item${
          maxQty === 0 || maxQty > 1 ? "s" : ""
        } at once!`
      );
  };

  return (
    <div className="input-group" style={{ width: "100px" }}>
      <button
        className="counter-btn"
        type="button"
        id="button-decrement"
        disabled={value === 0}
        onClick={handleDecrement}
      >
        <i className="fa-solid fa-minus"></i>
      </button>
      <div
        type="text"
        className="text-center border-0 d-flex align-items-center"
        style={{ width: "fit-content", padding: "0px 16px" }}
        placeholder=""
        aria-label="Example text with button addon"
        aria-describedby="button-addon1"
        disabled
      >
        {value}
      </div>
      <button
        className="counter-btn add"
        type="button"
        id="button-increment"
        onClick={handleIncrement}
      >
        <i className="fa-solid fa-plus"></i>
      </button>
    </div>
  );
};
