import { format, parse } from "date-fns";
import React from "react";
import { Collapse, Modal, Spinner } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  fetchOrderDetails,
  cancelOrder,
  trackOrder,
} from "../Services/OrderService";
import { TitleHeader } from "../Components/TitleHeader";
import { toast } from "react-toastify";
import { ConfirmationModal } from "../Components/ConfirmationModal";

export const OrderPlaced = ({
  title = "Order Details",
  subtitle = "Below are your order details.",
}) => {
  const [loading, setLoading] = React.useState(false);
  const [searchParams] = useSearchParams();
  const [details, setDetails] = React.useState(null);
  const [items, setItems] = React.useState([]);
  const [tax, setTax] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [deliveryType, setDeliveryType] = React.useState(null);
  // const [trackOrder, setTrackOrder] = React.useState(null);
  const [cLoading, setCLoading] = React.useState(false);
  const config = useSelector((x) => x.Config);
  const navigate = useNavigate();
  const [show, setShow] = React.useState(false);
  const [track, setTrack] = React.useState([]);
  const [trackLoading, setTrackLoading] = React.useState(false);
  const [cShow, setCShow] = React.useState(false);

  // React.useEffect(() => {
  //   setLoading(true);
  //   fetchDeliveryType(50, 1).then(
  //     (res) => {
  //       setLoading(false);
  //       setDeliveryType(
  //         res.find((x) => x.title?.trim()?.toLowerCase() === orderType)
  //       );
  //     },
  //     (er) => {
  //       console.warn(er, "delivery types failed");
  //     }
  //   );
  // }, []);
  const transformData = (data) => {
    const result = [];

    data.forEach((entry) => {
      const serviceId = entry.service.id;
      const existingService = result.find(
        (service) => service.services_id === serviceId
      );

      const item = {
        laundry_item_id: entry.laundry_item.id,
        price: entry.price,
        quantity: entry.quantity,
        name: entry.laundry_item.name,
        addons: [],
      };

      if (existingService) {
        existingService.items.push(item);
      } else {
        result.push({
          services_id: serviceId,
          service: entry.service.name,
          open: true,
          items: [item],
        });
      }
    });

    return result;
  };
  React.useEffect(() => {
    console.clear();
    const orderId = searchParams.get("orderId");
    if (!orderId) {
      navigate(-1, { replace: true });
    } else {
      setLoading(true);
      fetchOrderDetails(orderId).then(
        (res) => {
          setLoading(false);
          setItems(transformData(res.data.details));
          setTotal(
            res.data.order_amount -
              res.data.tax_amount -
              parseFloat(res.data.delivery_charge)
          );
          setTax(res.data.tax_amount);
          setDeliveryType(res.data.laundry_delivery_type);
          setDetails(res.data);
        },
        (er) => {
          console.warn(er, "error on order fetch");
        }
      );
    }
  }, [searchParams]);

  const cOrder = () => {
    if (!cLoading) {
      setCLoading(true);
      cancelOrder(details.id).then(
        (res) => {
          toast.success("Order cancelled Successfully!", {
            onClose: () => {
              document.location.reload();
            },
            autoClose: 1500,
          });
        },
        (er) => {
          setCLoading(false);
          toast.error("Something went wrong while canceling order!");
        }
      );
    }
  };
  const convertDate = (dateString) => {
    // Parse the date string into a Date object
    const date = parse(dateString, "yyyy-MM-dd HH:mm:ss", new Date());
    // Convert the Date object to an ISO string
    return date;
  };
  const transformStatus = (date, status) => {
    if (date) {
      const t = convertDate(date);
      return {
        status,
        time: format(t, "p"),
        date: format(t, "p, eee, dd MMM yyyy"),
      };
    }
  };
  const fetchTrack = () => {
    setTrackLoading(true);
    setShow(true);
    trackOrder(details?.id).then(
      (res) => {
        setTrackLoading(false);
        if (res.data) {
          const d = { ...res.data };
          const temp = [];
          const pending = transformStatus(d.pending, "Pending");
          if (pending) temp.push(pending);

          const confirmed = transformStatus(d.confirmed, "Confirmed");
          if (confirmed) temp.push(confirmed);

          const out_for_pickup = transformStatus(
            d.out_for_pickup,
            "Out For Pickup"
          );
          if (out_for_pickup) temp.push(out_for_pickup);

          const picked_up = transformStatus(d.picked_up, "Picked Up");
          if (picked_up) temp.push(picked_up);

          const arrived = transformStatus(d.arrived, "Arrived");
          if (arrived) temp.push(arrived);

          const processing = transformStatus(d.processing, "Processing");
          if (processing) temp.push(processing);

          const ready_for_delivery = transformStatus(
            d.ready_for_delivery,
            "Ready For Delivery"
          );
          if (ready_for_delivery) temp.push(ready_for_delivery);

          const out_for_delivery = transformStatus(
            d.out_for_delivery,
            "Out For Delivery"
          );
          if (out_for_delivery) temp.push(out_for_delivery);

          const delivered = transformStatus(d.delivered, "Delivered");
          if (delivered) temp.push(delivered);

          const deferred_at = transformStatus(d.deferred_at, "Deferred");
          if (deferred_at) temp.push(deferred_at);

          const cancelled = transformStatus(d.cancelled, "Cancelled");
          if (cancelled) temp.push(cancelled);

          setTrack(temp);
        }
      },
      () => {
        toast.error(
          "Something went wrong while fetching tracking please try again later!"
        );
        setShow(false);
      }
    );
  };

  // React.useEffect(() => {
  //   console.log(deliveryType);
  // }, [deliveryType]);
  return (
    <>
      <TitleHeader title={title} subtitle={subtitle} />
      <div className="container" style={{ minHeight: "80vh" }}>
        <div className="row justify-content-center py-4">
          <div className="col-xl-6 col-lg-7 col-md-10 col-12">
            {/* <h3 className="mb-1 fw-bold" style={{ letterSpacing: "-1px" }}>
            {title}
          </h3>
          <h6 className="fw-normal text-muted">{subtitle}</h6>
          <hr /> */}
            {loading ? (
              <div className="text-center">
                <Spinner variant="primary" />
              </div>
            ) : details ? (
              <>
                <div className="card mb-3">
                  <div className="card-body">
                    <h6 className="fw-bold mb-0">{details?.id}</h6>
                    <h5 className="fw-normal" style={{ color: "#828282" }}>
                      {format(details?.creation_date, "p, eee, dd MMM yyyy")}
                    </h5>
                    <hr />
                    {loading ? (
                      <div className="w-100 text-center py-3">
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      <Table borderless responsive className="mb-0">
                        <tbody>
                          {items.map((x, i) => (
                            <>
                              <tr>
                                <td
                                  className="fw-bold"
                                  style={{ fontSize: "14px" }}
                                >
                                  {x.service}
                                </td>
                                <td
                                  className="text-end"
                                  style={{ fontSize: "14px" }}
                                >
                                  <i className="fa-solid fa-chevron-down"></i>
                                </td>
                              </tr>
                              <Collapse in={x.open}>
                                <tr>
                                  <td colSpan={2}>
                                    <Table
                                      borderless
                                      responsive
                                      className="mb-0"
                                    >
                                      <tbody>
                                        {x.items.map((y) => (
                                          <>
                                            <tr>
                                              <td
                                                className={`py-1 ${
                                                  y.addons?.length > 0
                                                    ? "pb-0"
                                                    : ""
                                                }`}
                                              >
                                                {y.quantity} x {y.name}
                                              </td>
                                              <td
                                                className={`text-end py-1 ${
                                                  y.addons?.length > 0
                                                    ? "pb-0"
                                                    : ""
                                                }`}
                                              >
                                                RS. {y.price * y.quantity}
                                              </td>
                                            </tr>
                                            {y.addons?.length > 0 ? (
                                              <tr>
                                                <td
                                                  colSpan={2}
                                                  className="px-2 py-0"
                                                >
                                                  <Table borderless responsive>
                                                    <tbody>
                                                      {y.addons?.map((z) => (
                                                        <tr>
                                                          <td className="py-0">
                                                            {z.qty} x {z.name}
                                                          </td>
                                                          <td className="py-0 text-end">
                                                            RS.{" "}
                                                            {z.price * z.qty}
                                                          </td>
                                                        </tr>
                                                      ))}
                                                    </tbody>
                                                  </Table>
                                                </td>
                                              </tr>
                                            ) : (
                                              <></>
                                            )}
                                          </>
                                        ))}
                                      </tbody>
                                    </Table>
                                  </td>
                                </tr>
                              </Collapse>
                            </>
                          ))}
                        </tbody>
                        <tbody style={{ borderTop: "1px solid #aaa" }}>
                          <tr>
                            <td className="fw-semibold">Subtotal</td>
                            <td className="fw-bold text-end">RS. {total}</td>
                          </tr>

                          <tr>
                            <td className="fw-semibold">Tax</td>
                            <td className="fw-bold text-end">RS. {tax}</td>
                          </tr>
                          <tr>
                            <td className="fw-semibold">Delivery Fee</td>
                            <td className="fw-bold text-end">
                              RS. {parseFloat(details.delivery_charge)}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={2}>
                              <hr className="my-0" />
                            </td>
                          </tr>
                          <tr>
                            <td className="fw-semibold">Total</td>
                            <td className="fw-bold text-end">
                              RS. {details?.order_amount}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    )}
                  </div>
                </div>
                <div className="card mb-3">
                  <div className="card-body">
                    <h5 className="fw-bold">Scheduled Dates</h5>

                    <div className="col-12 d-flex flex-wrap align-items-center justify-content-between px-4 border border-gray rounded position-relative mt-4">
                      <div className="col-md-5 col-12">
                        <small
                          style={{ position: "absolute", top: "-12px" }}
                          className="bg-white px-2"
                        >
                          Pickup
                        </small>
                        <div className="d-flex align-items-center py-3 border-r border-gray">
                          <i
                            className="fa-regular fa-calendar-plus me-3"
                            style={{ fontSize: "22px", color: "#666" }}
                          ></i>
                          {details?.pickup_date} <br /> {details?.pickup_time}
                        </div>
                      </div>
                      <div className="col-1 d-md-block d-sm-none border-start border-gray h-100 py-2 border-2">
                        &nbsp;
                      </div>
                      <div className="col-md-5 col-12 ">
                        <small
                          style={{ position: "absolute", top: "-12px" }}
                          className="bg-white px-2"
                        >
                          Delivery
                        </small>
                        <div className="d-flex align-items-center py-3">
                          <i
                            className="fa-regular fa-calendar-plus me-3"
                            style={{ fontSize: "22px", color: "#666" }}
                          ></i>
                          {details?.delivery_date} <br />{" "}
                          {details?.order_delivery_time}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Note */}
                <div className="card mb-3">
                  <div className="card-body">
                    <h5 className="fw-bold mb-3">Appointment Note.</h5>
                    <p>{details.note}</p>
                  </div>
                </div>
                {/* Rider */}
                {details?.rider ? (
                  <div className="card mb-3">
                    <div className="card-body">
                      <h5 className="fw-bold mb-3">Rider Details</h5>
                      <div className="d-flex justify-content-between align-items-center flex-wrap">
                        <div className="d-flex align-items-center flex-wrap">
                          <img
                            style={{ backgroundColor: "#eee" }}
                            src={`${config?.base_urls?.delivery_man_image_url}/${details?.rider.image}`}
                            alt=""
                            className="avatar p-1 me-2"
                          />
                          <h6 className="mb-0">{`${details.rider.f_name} ${details.rider.l_name}`}</h6>
                        </div>
                        <a href={`tel:${details.rider.phone}`}>
                          <i className="fa-solid fa-phone d-block"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                ) : null}
                {/* <div className="card">
                  <div className="card-body">
                    <Link
                      className="btn btn-primary w-100 fw-semibold text-white"
                      to="/"
                    >
                      Go back to Home
                    </Link>
                  </div>
                </div> */}
              </>
            ) : null}
          </div>
        </div>
      </div>
      {details ? (
        <>
          <div className="container-fluid bg-white">
            <div
              className="row justify-content-center"
              style={{ padding: "40px 0px" }}
            >
              <div
                className="d-flex col-lg-6 col-md-7 col-12 justify-content-center flex-column flex-md-row"
                style={{ gap: "20px" }}
              >
                <div
                  className="text-center service-btn next"
                  onClick={() => {
                    fetchTrack();
                  }}
                >
                  Track Order
                </div>

                {details?.order_status === "pending" ? (
                  <div
                    className="text-center service-btn danger"
                    onClick={() => {
                      if (!cLoading) {
                        setCShow(true);
                      }
                    }}
                  >
                    {cLoading ? <Spinner size="sm" /> : null} Cancel Order
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <Modal
            show={show}
            fullscreen="md-down"
            centered
            onHide={() => {
              setShow(false);
              setTrack([]);
            }}
            style={{ zIndex: 1056, left: "-10px" }}
          >
            <Modal.Header closeButton className="py-2">
              <Modal.Title style={{ fontSize: "18px" }}>
                Order Status
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex flex-column align-items-center">
              {trackLoading ? (
                <div className="text-center">
                  <Spinner variant="primary" />
                </div>
              ) : track.length > 0 ? (
                <>
                  <div className="pe-4 w-100">
                    <ul className="app-timeline w-100">
                      {track.map((x, i) => (
                        <>
                          <li>
                            <div className="d-flex flex-wrap justify-content-between">
                              <span className="fw-semibold">{x.status}</span>
                              <span>{x.time}</span>
                            </div>
                            <span className="d-block mt-1">{x.date}</span>
                            {i !== track.length - 1 ? <hr /> : null}
                          </li>
                        </>
                      ))}
                    </ul>
                  </div>
                </>
              ) : (
                <h4 className="text-center text-uppercase text-muted">
                  No Records Found!
                </h4>
              )}
            </Modal.Body>
          </Modal>
        </>
      ) : null}
      <ConfirmationModal
        show={cShow}
        text="Are you sure you want to cancel this order? "
        onNo={() => {
          setCShow(false);
        }}
        onYes={() => {
          setCShow(false);
          cOrder();
          setCLoading(false);
        }}
      />
    </>
  );
};
