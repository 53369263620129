import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ItemCounter } from "../Components/ItemCounter";
import { baseUrl } from "../Services/Axios";
import {
  fetchCategories,
  fetchLaundryItem,
  fetchServices,
} from "../Services/CartService";
import { useFormik } from "formik";
import { AddonCounter } from "../Components/AddonCounter";
import { CartTotal } from "../Components/CartTotal";
import { Link } from "react-router-dom";
import _ from "lodash";
import { TitleHeader } from "../Components/TitleHeader";

export const Cart = () => {
  const [services, setServices] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [initLoading, setInitLoading] = React.useState(true);
  const [itemsLoading, setItemsLoading] = React.useState(false);
  const [laundryItems, setLaundryItem] = React.useState([]);
  const [campaign, setCampaign] = React.useState(null);
  const [payload, setPayload] = React.useState({
    serviceId: null,
    categoryId: null,
    limit: 5,
    offset: 1,
  });
  const lastService = useSelector((x) => x.Service);
  const lastCategory = useSelector((x) => x.Category);
  const cart = useSelector((x) => x.Cart);
  const config = useSelector((x) => x.Config);
  const orderType = useSelector((x) => x.OrderType);

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      cart: {
        items: [],
      },
    },
  });

  React.useEffect(() => {
    if (cart.length !== 0) {
      fetchServices(20, 1).then(
        (res) => {
          setServices(res);
          if (lastService) {
            setPayload((state) => ({
              ...state,
              serviceId: res.find((x) => x.id === parseInt(lastService))?.id,
            }));
          } else {
            setPayload((state) => ({ ...state, serviceId: res[0]?.id }));
          }
          fetchCategories().then(
            (res) => {
              setInitLoading(false);
              setCategories(res);
              if (lastCategory) {
                setPayload((state) => ({
                  ...state,
                  categoryId: res.find((x) => x.id === parseInt(lastCategory))
                    ?.id,
                }));
              } else {
                setPayload((state) => ({ ...state, categoryId: res[0]?.id }));
              }
            },
            (er) => {
              console.warn(er, "went wrong on categories");
            }
          );
        },
        (er) => {
          console.warn("Something went wrong on services...");
        }
      );
    }
  }, []);

  React.useEffect(() => {
    if (!initLoading && !itemsLoading) {
      setItemsLoading(true);
      fetchLaundryItem(payload).then(
        (res) => {
          console.log(res.campain[0]);
          setItemsLoading(false);
          setLaundryItem(res.data);
          setCampaign(res.campain[0]);
          formik.setValues((y) => ({
            ...y,
            cart: {
              ...y.cart,
              items: res.data
                .map((x) => {
                  const fCart = cart.find(
                    (f) =>
                      f.laundry_item_id === x.id &&
                      f.serviceId === x.services[0]?.id
                  );
                  const newPrice =
                    orderType === "express"
                      ? typeof x.services[0].pivot.e_price == "string"
                        ? parseFloat(x.services[0].pivot.e_price)
                        : x.services[0].pivot.e_price
                      : typeof x.services[0].pivot.price == "string"
                      ? parseFloat(x.services[0].pivot.price)
                      : x.services[0].pivot.price;
                  if (fCart) {
                    const fadb9 = {
                      ...fCart,
                      addons: x.addons.map((z) => {
                        const fAd = fCart.addons.find(
                          (ffAd) => ffAd.id === z.id
                        );
                        if (fAd) return fAd;
                        else
                          return {
                            id: z.id,
                            name: z.name,
                            price: z.price,
                            qty: 0,
                          };
                      }),
                      price: newPrice,
                      actualPrice: newPrice,
                    };
                    if (res.campain[0]) {
                      fadb9.price =
                        fadb9.price -
                        fadb9.price * (res.campain[0].discount / 100);
                    }
                    return fadb9;
                  }
                  // else {
                  //   const fadb9 = {
                  //     laundry_item_id: x.id,
                  //     price: newPrice,
                  //     actualPrice: newPrice,
                  //     quantity: 0,
                  //     name: x.name,
                  //     serviceId: x.services[0]?.id,
                  //     addons: x.addons.map((z) => {
                  //       return {
                  //         id: z.id,
                  //         name: z.name,
                  //         price: z.price,
                  //         qty: 0,
                  //       };
                  //     }),
                  //   };
                  //   if (res.campain[0]) {
                  //     fadb9.price =
                  //       fadb9.price -
                  //       fadb9.price * (res.campain[0].discount / 100);
                  //   }
                  //   return fadb9;
                  // }
                })
                .filter((x) => x),
            },
          }));
        },
        (er) => {
          setItemsLoading(false);
          console.warn(er, "error on laundry item.");
        }
      );
    }
  }, [initLoading, payload]);

  React.useEffect(() => {
    if (!initLoading) {
      const items = [...formik.values.cart.items];
      const currentCart = [...cart];
      const newItems = [...items];

      let updatedCart = currentCart.map((item) => {
        const newItem = newItems.find(
          (newItem) =>
            newItem.laundry_item_id === item.laundry_item_id &&
            newItem.serviceId === item.serviceId
        );
        return newItem || item;
      });

      newItems
        .filter((x) => x.quantity !== 0)
        .forEach((newItem) => {
          if (
            !updatedCart.find(
              (item) =>
                item.laundry_item_id === newItem.laundry_item_id &&
                item.serviceId === newItem.serviceId
            )
          ) {
            updatedCart.push(newItem);
          }
        });

      for (let i = 0; i < updatedCart.length; i++) {
        const x = updatedCart[i];
        const y = { ...x, addons: [] };

        if (x.quantity !== 0) {
          for (let j = 0; j < x.addons.length; j++) {
            const z = x.addons[j];
            if (z.qty !== 0) {
              y.addons.push(z);
            }
          }
          // newItems.push(y);
        }
        updatedCart[i] = y;
      }
      const itemsToFilter = updatedCart.filter((x) => x.quantity === 0);
      updatedCart = updatedCart.filter((x) => x.quantity !== 0);
      if (itemsToFilter.length > 0) {
        formik.setValues((v) => ({
          cart: {
            items: v.cart.items.filter((x) => x.quantity !== 0),
          },
        }));
      }

      console.log(updatedCart, "modified values from formik....");
      dispatch({ type: "setCart", payload: updatedCart });
    }
  }, [formik.values, initLoading, dispatch]);

  React.useEffect(() => {
    // console.clear();
    // console.log(cart);
    // console.log(services);
    // const the = cart.reduce((result, item) => {
    //   if (!result.includes(item.serviceId)) result.push(item.serviceId);
    //   return result;
    // }, []);
    // setServices((v) => v.filter((x) => the.includes(x.id)));
    // console.log(the);
    const uniqueServiceIds = new Set(cart.map((item) => item.serviceId));
    const ns = services.filter((service) => uniqueServiceIds.has(service.id));
    if (!_.isEqual(services, ns)) {
      setServices(ns);
    }
  }, [cart, services]);

  React.useEffect(() => {
    if (services.length > 0 && lastService) {
      const i = services.findIndex((x) => x.id == lastService);
      if (!~i) {
        dispatch({ type: "setService", payload: services[0]?.id });
        setPayload((state) => ({ ...state, serviceId: services[0]?.id }));
      }
    }
  }, [services, lastService]);

  const selectService = (serviceId) => {
    setPayload((state) => ({ ...state, serviceId }));
    dispatch({ type: "setService", payload: serviceId });
  };
  const selectCategory = (categoryId) => {
    setPayload((state) => ({ ...state, categoryId }));
    dispatch({ type: "setCategory", payload: categoryId });
  };
  const toggleAddons = (index) => {
    setLaundryItem((prevItems) =>
      prevItems.map((item, i) =>
        i === index ? { ...item, showAddons: !item.showAddons } : item
      )
    );
  };
  const changeItemQuantity = (index, value) => {
    formik.setValues((v) => ({
      cart: {
        items: v.cart.items.map((v, i) =>
          i === index ? { ...v, quantity: value } : v
        ),
      },
    }));
  };
  const changeAddonQuantity = (itemIndex, addonIndex, qty) => {
    formik.setValues((v) => ({
      cart: {
        items: v.cart.items.map((v, i) =>
          i === itemIndex
            ? {
                ...v,
                addons: v.addons.map((ad, adI) =>
                  addonIndex === adI ? { ...ad, qty } : ad
                ),
              }
            : v
        ),
      },
    }));
  };

  return (
    <>
      <TitleHeader title="My Cart" subtitle="Below are your cart details" />
      <div className="container py-3" style={{ minHeight: "35vh" }}>
        {cart.length === 0 ? (
          <div className="col-12 text-center">
            <h4 className="text-muted text-uppercase">Your cart is empty!</h4>
          </div>
        ) : initLoading ? (
          <>
            <div
              className="row justify-content-center align-items-center"
              style={{ height: "50vh" }}
            >
              <div
                className="spinner-border text-primary"
                role="status"
                style={{ width: "3rem", height: "3rem" }}
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          </>
        ) : (
          <>
            <div
              className="row justify-content-center row-gap-3 column-gap-3"
              style={{ padding: "20px 0px 20px" }}
            >
              {services.map((e) => (
                <div
                  className={`select-category-chip ${
                    e.id === payload.serviceId ? "selected" : ""
                  }`}
                  onClick={() => selectService(e.id)}
                >
                  {e.name}
                </div>
              ))}
            </div>
            <div
              className="row justify-content-start justify-content-md-center column-gap-3 row-gap-3 px-2 select-category-chip-container"
              style={{
                marginBottom: "30px",
                flexWrap: "nowrap",
                width: "100%",
                overflowX: "auto",
              }}
            >
              {categories.map((e) => (
                <div
                  className={`select-category-chip ${
                    e.id === payload.categoryId ? "selected" : ""
                  }`}
                  onClick={() => selectCategory(e.id)}
                >
                  {e.name}
                </div>
              ))}
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-8 col-md-10 col-12 my-3">
                <div
                  className="row justify-content-between align-items-start"
                  style={{ rowGap: "34px" }}
                >
                  {itemsLoading ? (
                    <div className="col-12 text-center">
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : formik.values.cart.items?.length === 0 ? (
                    <div className="col-auto d-flex align-items-center flex-column py-3">
                      <img
                        src="/assets/images/noitemicon.png"
                        alt=""
                        style={{ width: "70px" }}
                      />
                      <h6 className="mt-3 mb-0">
                        There are no laundry items in this category...
                      </h6>
                    </div>
                  ) : (
                    formik.values.cart.items?.map((e, itemIndex) => (
                      <div
                        className="service-laundry-item-card"
                        key={`item-${laundryItems[itemIndex]?.id}`}
                      >
                        <div className="inner">
                          <div className="service-laundry-item-details">
                            <img
                              src={`${config?.["base_urls"]?.["laundry_items_image_url"]}/${laundryItems[itemIndex]?.icon}`}
                              alt=""
                            />
                            <div className="inner">
                              <span className="name">{e.name}</span>
                              <span className="price">RS. {e.actualPrice}</span>
                            </div>
                          </div>
                          <ItemCounter
                            key={itemIndex}
                            value={e.quantity}
                            onChange={(v) => changeItemQuantity(itemIndex, v)}
                          />
                        </div>
                        {e.quantity > 0 &&
                        laundryItems[itemIndex]?.addons.length !== 0 ? (
                          <div className="col-md-9 col-12 mt-3">
                            <h6
                              className="text-uppercase cursor-pointer"
                              style={{
                                color: "#828282",
                                userSelect: "none",
                                width: "fit-content ",
                              }}
                              onClick={() => toggleAddons(itemIndex)}
                            >
                              Addons
                              <i
                                className={`fa-solid ms-2 fa-chevron-${
                                  laundryItems[itemIndex]?.showAddons
                                    ? "down"
                                    : "right"
                                }`}
                              ></i>
                            </h6>
                            {!laundryItems[itemIndex]?.showAddons
                              ? e.addons?.map((addon, addonIndex) => (
                                  <div className="d-flex justify-content-between align-items-center my-2">
                                    <p
                                      className="text-uppercase ms-2 mb-0"
                                      style={{ color: "#aaa" }}
                                    >
                                      {addon.name} (RS. {addon.price})
                                    </p>
                                    <AddonCounter
                                      key={`item-${e.id}-addon-${addon.id}`}
                                      value={addon.qty}
                                      max={e.quantity}
                                      onChange={(v) =>
                                        changeAddonQuantity(
                                          itemIndex,
                                          addonIndex,
                                          v
                                        )
                                      }
                                    />
                                  </div>
                                ))
                              : null}
                          </div>
                        ) : null}
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
            <CartTotal />
          </>
        )}
      </div>
      <div className="container-fluid bg-white">
        <div
          className="row justify-content-center"
          style={{ padding: "40px 0px" }}
        >
          <div
            className="d-flex col-lg-6 col-md-7 col-12 justify-content-center"
            style={{ columnGap: "20px" }}
          >
            <Link className="service-btn cart" to="/services">
              Continue
            </Link>
            {cart.length !== 0 ? (
              <Link to="/summary" className="service-btn next">
                Checkout
              </Link>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};
