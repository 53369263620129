import React from "react";
import { TitleHeader } from "../Components/TitleHeader";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { fetchCustomerInfo, updateCustomer } from "../Services/MainService";
import { toast } from "react-toastify";

const validationSchema = yup.object({
  f_name: yup.string().required("This field is required!"),
  l_name: yup.string().required("This field is required!"),
  email: yup
    .string()
    .required("This field is required!")
    .email("Please enter a valid email!"),
});

export const Profile = () => {
  const [submitting, setSubmitting] = React.useState(false);

  const customer = useSelector((x) => x.Customer);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      f_name: "",
      l_name: "",
      email: "",
    },
    validationSchema,
    onSubmit: (v) => {
      setSubmitting(true);
      if (!check(customer, v)) {
        updateCustomer(v).then(
          () => {
            fetchCustomerInfo().then(
              (res) => {
                setSubmitting(false);
                dispatch({ type: "setCustomer", payload: res });
              },
              (er) => {
                console.warn(er, "err while fetching customer...");
                toast.error(
                  "Something went wrong while fetching customer details..."
                );
              }
            );
          },
          () => {
            toast("Something went wrong while updating details...");
          }
        );
      }
    },
  });

  const check = (obj1, obj2) => {
    for (let key in obj2) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }
    return true;
  };
  React.useEffect(() => {
    if (customer) {
      const { f_name, l_name, email } = customer;
      formik.setValues({ f_name, l_name, email });
    }
  }, [customer]);

  return (
    <>
      <TitleHeader
        title="My Profile"
        subtitle="Below are your Profile Details"
      />
      <div
        className="container py-5"
        style={{ borderTop: "1px solid #E5E7EB" }}
      >
        <div className="row justify-content-center">
          <div className="col-lg-7 col-md-11 mt-3">
            <form className="row" onSubmit={formik.handleSubmit}>
              <div className="form-group col-md-6 col-12 py-3">
                <label className="fw-semibold form-label">First Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="First Name"
                  id="f_name"
                  name="f_name"
                  value={formik.values.f_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <small className="text-danger">
                  {formik.touched.f_name && formik.errors.f_name}
                </small>
              </div>
              <div className="form-group col-md-6 col-12 py-3">
                <label className="fw-semibold form-label">Last Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Last Name"
                  id="l_name"
                  name="l_name"
                  value={formik.values.l_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <small className="text-danger">
                  {formik.touched.l_name && formik.errors.l_name}
                </small>
              </div>
              <div className="form-group col-12 py-3">
                <label className="fw-semibold form-label">Email</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Email"
                  id="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <small className="text-danger">
                  {formik.touched.email && formik.errors.email}
                </small>
              </div>
              <div className="form-group col-12 py-3">
                <label className="fw-semibold form-label">
                  Phone (Non Changeable)
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Phone"
                  disabled
                  readOnly
                  defaultValue={customer.phone}
                />
              </div>
              <div className="form-group col-12 py-3">
                <button className="btn btn-primary w-100" type="submit">
                  Save Change
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
