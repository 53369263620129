import React from "react";

export const TitleHeader = ({ title, subtitle }) => {
  return (
    <div
      className="container-fluid bg-white"
      style={{ borderTop: "1px solid #E5E7EB" }}
    >
      <div
        className="row justify-content-center"
        style={{ padding: "20px 0px" }}
      >
        <div className="col-lg-6 col-md-8 col-12 text-center">
          <h1
            className="fw-bold"
            style={{
              fontSize: "30px",
              marginBottom: "13px",
              color: "#656565",
            }}
          >
            {title}
          </h1>
          <span
            style={{
              fontSize: "18px",
              color: "#656565",
              padding: "0px 30px",
              display: "inline-block",
            }}
          >
            {subtitle}
          </span>
        </div>
      </div>
    </div>
  );
};
