import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Router } from "./Router";
import { fetchConfig, fetchCustomerInfo } from "./Services/MainService";
import { UpperNav } from "./Components/UpperNav";
import { fetchOrders } from "./Services/OrderService";

function App() {
  const dispatch = useDispatch();
  const loggedIn = useSelector((x) => x.LoggedIn);
  const config = useSelector((x) => x.Config);
  React.useEffect(() => {
    fetchConfig().then(
      (res) => {
        dispatch({ type: "setConfig", payload: res });
      },
      (er) => {
        console.warn(er, "err while fetching config...");
        toast.error("Something went wrong while fetching configuration...");
      }
    );
  }, []);
  React.useEffect(() => {
    if (loggedIn) {
      fetchCustomerInfo().then(
        (res) => {
          dispatch({ type: "setCustomer", payload: res });
        },
        (er) => {
          console.warn(er, "err while fetching customer...");
          toast.error(
            "Something went wrong while fetching customer details..."
          );
        }
      );
      fetchOrders({
        limit: config?.laundry_customer_maximum_order,
        offset: 1,
      }).then(
        (res) => {
          dispatch({ type: "setOngoingOrders", payload: res.data });
        },
        (er) => {
          if (er && er.errors && er.errors.length > 0)
            er.errors.forEach((x) => toast.error(x.message));
          else
            toast.error("Something went wrong while fetching Ongoing Orders!");
        }
      );
    }
  }, [loggedIn]);
  return (
    <>
      <Router />
      <ToastContainer />
    </>
  );
}

export default App;
