import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export const PaymentSuccess = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  React.useEffect(() => {
    const id = params.get("id");
    if (id) navigate(`/order-placed?orderId=${id}`);
    else navigate("/");
  }, [params]);
  return <div>PaymentSuccess</div>;
};
