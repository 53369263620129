import React, { useEffect, useRef, useState } from "react";
import { Nav } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { clear } from "../Services/Storage";
import { AddressPicker } from "./AddressPicker";

const NavDropdownMenu = ({ name }) => {
  const [showDrp, setShowDrp] = useState(false);
  const [showSubDrp, setSubShowDrp] = useState(false);
  const [showAddress, setShowAddress] = React.useState(false);
  const dropdownRef = useRef(null);
  const subDropdownRef = useRef(null);
  const toggleDropdownRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Logout = () => {
    dispatch({ type: "setToken", payload: null });
    dispatch({ type: "setOrderType", payload: null });
    dispatch({ type: "setService", payload: null });
    dispatch({ type: "setCategory", payload: null });
    dispatch({ type: "setCart", payload: [] });
    dispatch({ type: "setLoggedIn", payload: false });
    dispatch({ type: "setZone", payload: null });
    dispatch({ type: "setCustomer", payload: null });
    dispatch({ type: "setAddress", payload: null });
    dispatch({ type: "setAddresses", payload: null });
    dispatch({ type: "setOngoingOrders", payload: null });
    dispatch({ type: "setNote", payload: null });
    dispatch({ type: "setPickupDate", payload: null });
    dispatch({ type: "setDeliveryDate", payload: null });
    dispatch({ type: "setCoupon", payload: null });
    clear();
    setSubShowDrp(!showSubDrp);
    setShowDrp(!showDrp);
    navigate("/", { replace: true });
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      subDropdownRef.current &&
      !subDropdownRef.current.contains(event.target) &&
      toggleDropdownRef.current &&
      !toggleDropdownRef.current.contains(event.target)
    ) {
      setShowDrp(false);
      setSubShowDrp(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <Nav.Link
        className="pb-md-0 d-flex align-items-center fw-normal nav-drop"
        as={"div"}
      >
        <span
          className="nav-drop-toggle"
          onClick={() => setShowDrp(!showDrp)}
          ref={toggleDropdownRef}
        >
          <img
            src="/assets/svgs/icons/user-icon.svg"
            alt=""
            style={{ marginRight: "6px" }}
          />
          Welcome, {name.split(" ")[0]}
        </span>
        <div
          ref={dropdownRef}
          className={`nav-drop-menu ${showDrp ? "show-drop" : ""}`}
        >
          <div className="nav-drop-heading">{name}</div>
          <hr style={{ margin: "8px 0px 4px 0px" }} />
          <Link
            to="/ongoing-orders"
            className="nav-drop-item"
            onClick={() => {
              setSubShowDrp(!showSubDrp);
              setShowDrp(!showDrp);
            }}
          >
            <div className="nav-drop-hover">
              <img src="/assets/svgs/icons/bag-icon-1.svg" alt="" />
              <span>Ongoing Orders</span>
            </div>
          </Link>
          <Link
            className="nav-drop-item"
            onClick={() => {
              setSubShowDrp(!showSubDrp);
              setShowDrp(!showDrp);
            }}
            to="/orders-history"
          >
            <div className="nav-drop-hover">
              <img src="/assets/svgs/icons/bag-icon-1.svg" alt="" />
              <span>Orders History</span>
            </div>
          </Link>
          <Link
            className="nav-drop-item"
            onClick={() => {
              setSubShowDrp(!showSubDrp);
              setShowDrp(!showDrp);
            }}
            to="/notifications"
          >
            <div className="nav-drop-hover">
              <img src="/assets/svgs/icons/notification.svg" alt="" />
              <span>Notification</span>
            </div>
          </Link>
          <hr style={{ margin: "4px 0px" }} />
          <div
            className="nav-drop-item"
            onClick={() => setSubShowDrp(!showSubDrp)}
          >
            <div className="nav-drop-hover">
              <img src="/assets/svgs/icons/user-icon.svg" alt="" />
              <span>My Profile</span>
            </div>
            <div
              ref={subDropdownRef}
              className={`nav-drop-menu ${showSubDrp ? "show-drop" : ""}`}
            >
              <Link
                to="/profile"
                className="nav-drop-item"
                onClick={() => {
                  setSubShowDrp(!showSubDrp);
                  setShowDrp(!showDrp);
                }}
              >
                <div className="nav-drop-hover">
                  <span>Profile</span>
                </div>
              </Link>
              <div
                className="nav-drop-item"
                onClick={() => {
                  setSubShowDrp(!showSubDrp);
                  setShowDrp(!showDrp);
                  setShowAddress(true);
                }}
              >
                <div className="nav-drop-hover">
                  <span>Addresses</span>
                </div>
              </div>
              <div
                className="nav-drop-item"
                onClick={() => {
                  setSubShowDrp(!showSubDrp);
                  setShowDrp(!showDrp);
                }}
              >
                <div className="nav-drop-hover">
                  <span>Help & Support</span>
                </div>
              </div>
              <div
                className="nav-drop-item"
                onClick={() => {
                  setSubShowDrp(!showSubDrp);
                  setShowDrp(!showDrp);
                }}
              >
                <div className="nav-drop-hover">
                  <span>Service Review</span>
                </div>
              </div>
              <div
                className="nav-drop-item"
                onClick={() => {
                  setSubShowDrp(!showSubDrp);
                  setShowDrp(!showDrp);
                }}
              >
                <div className="nav-drop-hover">
                  <span>About Jabchaho</span>
                </div>
              </div>
              <div
                className="nav-drop-item"
                onClick={() => {
                  setSubShowDrp(!showSubDrp);
                  setShowDrp(!showDrp);
                }}
              >
                <div className="nav-drop-hover">
                  <span>Privacy Policy </span>
                </div>
              </div>
            </div>
          </div>
          <hr style={{ margin: "4px 0px" }} />
          <div className="nav-drop-item mb-0" onClick={() => Logout()}>
            <div className="nav-drop-hover">
              <img src="/assets/svgs/icons/logout.svg" alt="" />
              <span>Logout</span>
            </div>
          </div>
        </div>
      </Nav.Link>
      <AddressPicker
        show={showAddress}
        closeButton={true}
        onHide={() => setShowAddress(false)}
        title="Below are your addresses!"
        editOnSelect={true}
      />
    </>
  );
};

export default NavDropdownMenu;
