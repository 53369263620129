import { addDays, eachDayOfInterval, format } from "date-fns";
import React from "react";
import { Collapse, Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AddressPicker } from "../Components/AddressPicker";
import { ConfirmationModal } from "../Components/ConfirmationModal";
import { DatePicker } from "../Components/DatePicker";
import useCartTotal from "../Hooks/useCartTotal";
import { fetchDeliveryType, fetchServices } from "../Services/CartService";
import { fetchCoupon, fetchWeekDaysOff } from "../Services/OrderService";
import { fetchTimeSlot } from "../Services/MainService";
import { TitleHeader } from "../Components/TitleHeader";

export const Summary = () => {
  const [amount, count] = useCartTotal();
  const config = useSelector((x) => x.Config);
  const orderType = useSelector((x) => x.OrderType);
  const cart = useSelector((x) => x.Cart);
  const coupon = useSelector((x) => x.Coupon);
  const pickupDate = useSelector((x) => x.PickupDate);
  const deliveryDate = useSelector((x) => x.DeliveryDate);
  const zone = useSelector((x) => x.Zone);
  const note = useSelector((x) => x.Note);
  const addresses = useSelector((x) => x.Addresses);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loading, setLoading] = React.useState(false);
  const [deliveryType, setDeliveryType] = React.useState(null);
  const [showPickup, setShowPickup] = React.useState(false);
  const [showDelivery, setShowDelivery] = React.useState(false);
  const [pickupRange, setPickupRange] = React.useState([]);
  const [sPickup, setSPickup] = React.useState(null);
  const [sPSlot, setSPSlot] = React.useState(null);
  const [deliveryRange, setDeliveryRange] = React.useState([]);
  const [sDelivery, setSDelivery] = React.useState(null);
  const [sDSlot, setSDSlot] = React.useState(null);
  const [cLoading, setCLoading] = React.useState(false);
  const [code, setCode] = React.useState(coupon?.code ?? "");
  const [addressModal, setAddressModal] = React.useState(null);
  const [address, setAddress] = React.useState({
    pickup: null,
    delivery: null,
  });
  const [items, setItems] = React.useState([]);
  const [services, setServices] = React.useState([]);
  const [loaded, setLoaded] = React.useState(false);
  const [tAddress, setTAddress] = React.useState(null);
  const [weekDaysOff, setWeekDaysOff] = React.useState(null);

  const setNote = (e) => {
    dispatch({ type: "setNote", payload: e });
  };

  React.useEffect(() => {
    setLoading(true);
    if (cart && cart.length > 0) {
      fetchDeliveryType(50, 1).then(
        (res) => {
          // setLoading(false);
          setDeliveryType(
            res.find((x) => x.title?.trim()?.toLowerCase() === orderType)
          );
        },
        (er) => {
          console.warn(er, "delivery types failed");
          // setTypesLoading(false);
        }
      );
      fetchWeekDaysOff().then(
        (res) => setWeekDaysOff(res),
        () => toast.error("Something went wrong!")
      );
    } else navigate("/", { replace: true });
  }, [cart]);
  React.useEffect(() => {
    if (weekDaysOff) {
      const sd = addDays(new Date(), 1);
      const ed = addDays(sd, 14);
      const dif = FilterRange(eachDayOfInterval({ start: sd, end: ed }));
      if (pickupDate?.date && pickupDate?.slot) {
        fetchTimeSlot(
          format(pickupDate?.date, "yyyy-MM-dd"),
          1,
          orderType
        ).then(
          (res) => {
            const sl = res.findIndex((x) => x.id === pickupDate.slot.id);
            if (~sl) {
              setSPickup(pickupDate?.date);
              setSPSlot(pickupDate?.slot);
            } else
              toast.warn(
                "Pickup Time slot you selected last time is not available now please select another slot!"
              );
          },
          (er) => {
            console.warn(er, "error on slot");
            toast.error("Something went wrong while fetching Time Slots.");
          }
        );
      }
      // if (pickupDate?.date) {
      //   setSPickup(pickupDate?.date);
      // }
      // if (pickupDate?.slot) {
      //   setSPSlot(pickupDate?.slot);
      // }
      setPickupRange(dif);
    }
  }, [pickupDate, weekDaysOff]);
  React.useEffect(() => {
    if (weekDaysOff) {
      if (sPickup && deliveryType) {
        console.log(deliveryType);

        const sd = addDays(sPickup, deliveryType?.duration);
        const ed = addDays(sd, 14);
        const dif = FilterRange(eachDayOfInterval({ start: sd, end: ed }));
        if (deliveryDate?.date && pickupDate?.slot) {
          fetchTimeSlot(
            format(deliveryDate?.date, "yyyy-MM-dd"),
            1,
            orderType
          ).then(
            (res) => {
              const sl = res.findIndex((x) => x.id === deliveryDate?.slot.id);
              if (~sl) {
                setSDelivery(deliveryDate?.date);
                setSDSlot(pickupDate?.slot);
              } else
                toast.warn(
                  "Delivery Time slot you selected last time is not available now please select another slot!"
                );
            },
            (er) => {
              console.warn(er, "error on slot");
              toast.error("Something went wrong while fetching Time Slots.");
            }
          );
        }
        // if (deliveryDate?.date) {
        //   setSDelivery(deliveryDate?.date);
        // }
        // if (deliveryDate?.slot) {
        //   setSDSlot(deliveryDate?.slot);
        // }
        setDeliveryRange(dif);
      }
    }
  }, [sPickup, deliveryType, deliveryDate, weekDaysOff]);
  React.useEffect(() => {
    if (address.delivery || address.pickup) {
      dispatch({ type: "setAddresses", payload: address });
    }
  }, [address]);
  React.useEffect(() => {
    if (!loaded) {
      if (addresses) {
        setAddress(addresses);
      }
      setLoaded(true);
    }
  }, [addresses, loaded]);

  const HandlePickupChange = (e) => {
    setSPickup(e.date);
    setSPSlot(e.slot);
    dispatch({ type: "setPickupDate", payload: e });
    setShowPickup(false);
  };
  const HandleDeliveryChange = (e) => {
    setSDelivery(e.date);
    setSDSlot(e.slot);
    dispatch({ type: "setDeliveryDate", payload: e });
    setShowDelivery(false);
  };
  const checkCoupon = () => {
    if (coupon) {
      dispatch({ type: "setCoupon", payload: null });
      setCode("");
    } else if (code.trim() !== "" && !cLoading) {
      setCLoading(true);
      fetchCoupon({
        code,
        amount,
        zone_id: zone,
      }).then(
        (res) => {
          setCLoading(false);
          console.log(res);
          dispatch({ type: "setCoupon", payload: res.coupon });
        },
        (err) => {
          setCLoading(false);
          err?.errors?.forEach((er) => {
            toast.error(er.message);
          });
        }
      );
    }
  };
  const HandleAddressChange = (e) => {
    if (e.laundry_zone_id == zone) {
      if (addressModal === "pickup") {
        setAddress((v) => ({ ...v, pickup: e }));
      } else {
        setAddress((v) => ({ ...v, delivery: e }));
      }
      setAddressModal(null);
    } else {
      setTAddress(e);
    }
  };
  const ReplaceAddress = (e) => {
    if (addressModal === "pickup") {
      setAddress((v) => ({ pickup: e, delivery: null }));
    } else {
      setAddress((v) => ({ pickup: null, delivery: e }));
    }
    setAddressModal(null);
    setTAddress(null);

    dispatch({ type: "setZone", payload: e.laundry_zone_id });
    dispatch({ type: "setCart", payload: [] });
    dispatch({ type: "setOrderType", payload: null });
    dispatch({ type: "setService", payload: null });
    dispatch({ type: "setCategory", payload: null });
  };
  const FilterRange = (range) => {
    return range.filter((date) => {
      const day = format(date, "EEEE").toLowerCase();
      const dateStringFormatted = format(date, "yyyy-MM-dd");

      return (
        !weekDaysOff?.weekdaysOff.includes(day) &&
        !weekDaysOff?.offDays.includes(dateStringFormatted)
      );
    });
  };
  React.useEffect(() => {
    setLoading(true);
    fetchDeliveryType(50, 1).then(
      (res) => {
        setDeliveryType(
          res.find((x) => x.title?.trim()?.toLowerCase() === orderType)
        );
        fetchServices(50, 1).then(
          (res) => {
            setServices(res);
          },
          (err) => console.warn(err, "err while fetching services...")
        );
      },
      (er) => {
        console.warn(er, "delivery types failed");
        // setTypesLoading(false);
      }
    );
  }, []);
  React.useEffect(() => {
    if (services.length !== 0 && cart.length !== 0) {
      setLoading(true);
      const temp = cart.reduce((result, item) => {
        const key = item.serviceId;
        if (!result[key]) {
          result[key] = {
            services_id: key,
            service: services.find((s) => s.id === key)?.name,
            open: true,
            items: [],
          };
        }
        const { laundry_item_id, price, quantity, bar_code, name, addons } =
          item;
        result[key].items.push({
          laundry_item_id,
          price,
          quantity,
          bar_code,
          name,
          addons,
        });
        return result;
      }, {});
      const tempTemp = Object.values(temp);
      const anotherTemp = [];
      for (let i = 0; i < tempTemp.length; i++) {
        const x = tempTemp[i];
        anotherTemp.push(x);
      }
      setItems(anotherTemp);
      setLoading(false);
    }
  }, [cart, services]);

  return (
    <>
      <TitleHeader
        title={"Order Summary"}
        subtitle={
          "Review your order details and get ready for a fresh, clean wardrobe!"
        }
      />
      <div className="container">
        <div className="row justify-content-center py-4">
          <div className="col-xl-6 col-lg-7 col-md-10 col-12">
            {/* Pricing Details */}
            <div className="card  mb-3">
              <div className="card-body">
                {loading ? (
                  <div className="w-100 text-center py-3">
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <Table borderless responsive className="mb-0">
                    <tbody>
                      {items.map((x, i) => (
                        <>
                          <tr>
                            <td
                              className="fw-bold"
                              style={{ fontSize: "14px" }}
                            >
                              {x.service}
                            </td>
                            {/* <td className="text-end" style={{ fontSize: "14px" }}>
                            <i className="fa-solid fa-chevron-down"></i>
                          </td> */}
                          </tr>
                          <Collapse in={x.open}>
                            <tr>
                              <td colSpan={2}>
                                <Table borderless responsive className="mb-0">
                                  <tbody>
                                    {x.items.map((y) => (
                                      <>
                                        <tr>
                                          <td
                                            className={`py-1 ${
                                              y.addons?.length > 0 ? "pb-0" : ""
                                            }`}
                                          >
                                            {y.quantity} x {y.name}
                                          </td>
                                          <td
                                            className={`text-end py-1 ${
                                              y.addons?.length > 0 ? "pb-0" : ""
                                            }`}
                                          >
                                            RS. {y.price * y.quantity}
                                          </td>
                                        </tr>
                                        {y.addons?.length > 0 ? (
                                          <tr>
                                            <td
                                              colSpan={2}
                                              className="px-2 py-0"
                                            >
                                              <Table borderless responsive>
                                                <tbody>
                                                  {y.addons?.map((z) => (
                                                    <tr>
                                                      <td className="py-0">
                                                        {z.qty} x {z.name}
                                                      </td>
                                                      <td className="py-0 text-end">
                                                        RS. {z.price * z.qty}
                                                      </td>
                                                    </tr>
                                                  ))}
                                                </tbody>
                                              </Table>
                                            </td>
                                          </tr>
                                        ) : (
                                          <></>
                                        )}
                                      </>
                                    ))}
                                  </tbody>
                                </Table>
                              </td>
                            </tr>
                          </Collapse>
                        </>
                      ))}
                    </tbody>
                    <tbody style={{ borderTop: "1px solid #aaa" }}>
                      <tr>
                        <td className="fw-semibold">Subtotal</td>
                        <td className="fw-bold text-end">RS. {amount}</td>
                      </tr>
                      {coupon ? (
                        <tr>
                          <td className="fw-semibold">Discount</td>
                          <td className="fw-bold text-end">
                            -RS. {amount * (coupon.discount / 100)}
                          </td>
                        </tr>
                      ) : null}
                      <tr>
                        <td className="fw-semibold">Tax</td>
                        <td className="fw-bold text-end">
                          RS. {amount * (config.laundry_tax / 100)}
                        </td>
                      </tr>
                      <tr>
                        <td className="fw-semibold">Delivery Fee</td>
                        <td className="fw-bold text-end">
                          RS.{" "}
                          {amount >= config.laundry_free_delivery
                            ? 0
                            : deliveryType?.charge}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <hr className="my-0" />
                        </td>
                      </tr>
                      <tr>
                        <td className="fw-semibold">Total</td>
                        <td className="fw-bold text-end">
                          RS.{" "}
                          {amount -
                            (coupon ? amount * (coupon.discount / 100) : 0) +
                            amount * (config.laundry_tax / 100) +
                            (amount >= config.laundry_free_delivery
                              ? 0
                              : deliveryType?.charge)}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                )}
              </div>
            </div>
            {/* Coupon */}
            <div className="card mb-3">
              <div className="card-body">
                <span className="fw-semibold mb-3 d-block">Apply Coupon</span>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Coupon Code"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                  <button
                    className={`btn text-white fw-semibold ${
                      coupon ? "btn-danger" : "btn-primary"
                    }`}
                    type="button"
                    onClick={() => checkCoupon()}
                    disabled={cLoading}
                  >
                    {cLoading ? (
                      <Spinner variant="white" size="sm" />
                    ) : coupon ? (
                      "Remove"
                    ) : (
                      "Apply"
                    )}
                  </button>
                </div>
              </div>
            </div>
            {/* Address */}
            <div className="card mb-3">
              <div className="card-body">
                <span className="fw-semibold mb-3 d-block">Address</span>
                <div
                  className="row cursor-pointer"
                  onClick={() => setAddressModal("pickup")}
                >
                  <div className="col-1 text-end pe-0">
                    <i
                      className="fa-solid fa-circle-dot"
                      style={{ color: "#32b6e9" }}
                    ></i>
                  </div>
                  <div className="col-11">
                    <h6 className="mb-1">Pickup Address</h6>
                    <p className="m-0">
                      {address.pickup
                        ? address.pickup?.address
                        : "Select address"}
                    </p>
                    {/* <hr className="border-2" /> */}
                  </div>
                </div>
                <div style={{ margin: "20px 0px" }}></div>
                <div
                  className="row cursor-pointer"
                  onClick={() => setAddressModal("delivery")}
                >
                  <div className="col-1 text-end pe-0">
                    <i
                      className="fa-solid fa-location-dot text-primary"
                      style={{ fontSize: "20px" }}
                    ></i>
                  </div>
                  <div className="col-11">
                    <h6 className="mb-1">Delivery Address</h6>
                    <p className="m-0">
                      {address.delivery
                        ? address.delivery?.address
                        : "Select address"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* Date pickers */}
            <div className="card mb-3">
              <div className="card-body">
                <span className="fw-semibold mb-3 d-block">Schedule Dates</span>

                <div className="app-dates-container border border-gray rounded mt-4">
                  <div className="col-12 col-md-5 px-4">
                    <small
                      style={{ position: "absolute", top: "-12px" }}
                      className="bg-white px-2"
                    >
                      Pickup
                    </small>
                    <div
                      className="d-flex align-items-center py-3 border-r border-gray cursor-pointer"
                      onClick={() => setShowPickup(true)}
                    >
                      <i
                        className="fa-regular fa-calendar-plus me-3"
                        style={{ fontSize: "22px", color: "#666" }}
                      ></i>
                      {sPickup ? format(sPickup, "eee, do MMM") : "Date"}
                      {sPSlot && sPickup ? <br /> : "/"}
                      {sPSlot
                        ? `${sPSlot?.start_time} - ${sPSlot?.end_time}`
                        : "Time"}
                    </div>
                  </div>
                  <div className="col-1 d-none d-md-block border-start border-gray h-100 py-2 border-2">
                    &nbsp;
                  </div>
                  <hr className="w-100 my-1 d-block d-md-none" />
                  <div className="col-12 col-md-5 px-4">
                    <small
                      style={{ position: "absolute" }}
                      className="bg-white px-2 app-dates-delivery-label"
                    >
                      Delivery
                    </small>
                    <div
                      className="d-flex align-items-center py-3 cursor-pointer"
                      onClick={() => {
                        if (sPickup && sPSlot) setShowDelivery(true);
                        else
                          toast.error(
                            "Please select pickup date & slot first..."
                          );
                      }}
                    >
                      <i
                        className="fa-regular fa-calendar-plus me-3"
                        style={{ fontSize: "22px", color: "#666" }}
                      ></i>
                      {sDelivery ? format(sDelivery, "eee, do MMM") : "Date"}
                      {sDSlot && sDelivery ? <br /> : "/"}
                      {sDSlot
                        ? `${sDSlot?.start_time} - ${sDSlot?.end_time}`
                        : "Time"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Note */}
            <div className="card mb-3">
              <div className="card-body">
                <span className="fw-semibold mb-3 d-block">
                  Order Note / Instructions
                </span>
                <textarea
                  className="form-control"
                  rows="3"
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-white">
        <div
          className="row justify-content-center"
          style={{ padding: "40px 0px" }}
        >
          <div
            className="d-flex col-lg-6 col-md-7 col-12 justify-content-center"
            style={{ columnGap: "20px" }}
          >
            <div
              className="service-btn cart"
              onClick={() => {
                navigate(-1);
              }}
            >
              Back
            </div>
            {/* { deliveryDate && pickupDate ? (
              <Link className="service-btn next" to="/order-review">
                Next
              </Link>
            ) : ( */}
            <button
              className="service-btn next"
              onClick={() => {
                if (!deliveryDate || !pickupDate) {
                  toast.error(
                    "Please select pickup & delivery date to continue..."
                  );
                  return;
                }
                if (!address.pickup || !address.delivery) {
                  toast.error(
                    "Please select pickup & delivery addresses to continue..."
                  );
                  return;
                }
                if (
                  deliveryDate &&
                  pickupDate &&
                  address.pickup &&
                  address.delivery
                ) {
                  navigate("/order-review");
                }
              }}
            >
              Next
            </button>
            {/* )} */}
          </div>
        </div>
      </div>
      {/* Pickup date modal*/}
      <Modal
        show={showPickup}
        backdrop="static"
        keyboard={false}
        centered
        onHide={() => setShowPickup(false)}
      >
        <Modal.Header closeButton className="py-2">
          <Modal.Title style={{ fontSize: "18px" }}>
            Choose Preferred Date / Time
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DatePicker
            range={pickupRange}
            selected={sPickup}
            onChange={HandlePickupChange}
            orderType={deliveryType?.id}
            timeSlot={sPSlot}
            type={1}
          />
        </Modal.Body>
      </Modal>
      {/* delivery date modal*/}
      <Modal
        show={showDelivery}
        backdrop="static"
        keyboard={false}
        centered
        onHide={() => setShowDelivery(false)}
      >
        <Modal.Header closeButton className="py-2">
          <Modal.Title style={{ fontSize: "18px" }}>
            Choose Preferred Date / Time
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DatePicker
            range={deliveryRange}
            selected={sDelivery}
            onChange={HandleDeliveryChange}
            orderType={deliveryType?.id}
            timeSlot={sDSlot}
            type={2}
          />
        </Modal.Body>
      </Modal>
      {/* Addresses modal*/}

      <AddressPicker
        show={Boolean(addressModal)}
        closeButton={true}
        onHide={() => setAddressModal(null)}
        title={` Select Your ${
          addressModal === "pickup" ? "Pickup " : "Delivery"
        } Address`}
        onSelect={HandleAddressChange}
      />
      <ConfirmationModal
        show={Boolean(tAddress)}
        text="The Address you selected is not in the same zone as you selected in start if you change your zone your cart will be empty. Are your sure you want to change your address?"
        onYes={() => ReplaceAddress(tAddress, true)}
        onNo={() => setTAddress(null)}
      />
    </>
  );
};
