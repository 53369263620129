import React from "react";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { TitleHeader } from "../Components/TitleHeader";
import { fetchOrdersHistory } from "../Services/OrderService";

export const OrdersHistory = () => {
  const [orders, setOrders] = React.useState([]);
  const [total, setTotal] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [payload, setPayload] = React.useState({
    limit: 10,
    offset: 1,
  });
  const navigate = useNavigate();
  React.useEffect(() => {
    setLoading(true);
    fetchOrdersHistory(payload).then(
      (res) => {
        setLoading(false);
        setOrders((x) => [...x, ...res.data]);
        setTotal(res.total_size);
      },
      (er) => {
        if (er && er.errors && er.errors.length > 0)
          er.errors.forEach((x) => toast.error(x.message));
        else toast.error("Something went wrong...");
      }
    );
  }, [payload]);
  return (
    <>
      <TitleHeader
        title="Order History"
        subtitle="Below are your Order History"
      />
      <div className="container">
        <div
          className="row flex-column align-items-center py-3"
          style={{ minHeight: "100vh" }}
        >
          {!loading && orders.length > 0 ? (
            orders.map((x) => (
              <div className="col-lg-6 col-md-9 col-12 py-1">
                <div
                  className="card cursor-pointer"
                  onClick={() => navigate(`/order-details?orderId=${x.id}`)}
                >
                  <div className="card-body d-flex justify-content-between">
                    <img src="/assets/images/deliverybox.png" alt="" />
                    <div className="ms-4">
                      <h5 className="">{x.id}</h5>
                      <div className="d-flex">
                        <div>
                          <h6 className="mb-0 fw-bold">{x.pickup_time}</h6>
                          <span className="fw-semibold text-muted">
                            {x.pickup_date}
                          </span>
                        </div>
                        <div className="d-flex align-items-center mx-3">
                          <div
                            className="fa-solid fa-circle-dot text-primary"
                            style={{ fontSize: "12px" }}
                          ></div>
                          <hr
                            style={{ width: "30px", borderStyle: "dashed" }}
                            className="mx-1"
                          />
                          <div
                            className="fa-solid fa-circle text-primary"
                            style={{ fontSize: "12px" }}
                          ></div>
                        </div>
                        <div>
                          <h6 className="mb-0 fw-bold">
                            {x.order_delivery_time}
                          </h6>
                          <span className="fw-semibold text-muted">
                            {x.delivery_date}
                          </span>
                        </div>
                      </div>
                    </div>
                    <span className="text-primary fw-semibold">
                      RS. {x.order_amount}
                    </span>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <h4 className="text-muted text-center text-uppercase">
              No records found!
            </h4>
          )}
          <div className="col-12 text-center">
            {loading ? (
              <Spinner variant="primary" />
            ) : orders.length !== total ? (
              <>
                <Button
                  variant="primary"
                  className="fw-semibold text-white px-4"
                  onClick={() =>
                    setPayload((x) => ({ ...x, offset: x.offset + 1 }))
                  }
                >
                  Load More
                </Button>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};
