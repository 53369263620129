import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const RouteProtector = ({ children }) => {
  const loggedIn = useSelector((x) => x.LoggedIn);
  let location = useLocation();
  const navigate = useNavigate();
  let [isOpen, setIsOpen] = React.useState(true);
  // React.useEffect(() => {
  //   console.log(location);
  //   console.log(loggedIn);
  // }, [loggedIn]);
  if (!loggedIn) {
    return (
      <>
      <div className="container" style={{height:'80vh'}}></div>
        <Modal
          show={isOpen}
          // onHide={() => setIsOpen(false)}
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Body className="pt-0">
            <div className="d-flex justify-content-center">
              <img src="/assets/images/not-login.png" className="w-75" alt="" />
            </div>
            <h5 className="fw-normal text-center">
              You're not logged-in please login or register to continue...
            </h5>
          </Modal.Body>
          <Modal.Footer className="border-0 justify-content-center">
            <div className="d-flex flex-wrap justify-content-between w-100">
              <Button
                variant="warning"
                className="m-0 text-white fw-semibold"
                style={{ width: "49%" }}
                onClick={() => {
                  setIsOpen(false);
                  navigate("/login", { state: { from: location.pathname } });
                }}
              >
                Login
              </Button>
              <Button
                variant="primary"
                className="m-0 bg-dark text-white fw-semibold"
                style={{ width: "49%" }}
                onClick={() => {
                  setIsOpen(false);
                  navigate("/sign-up", { state: { from: location.pathname } });
                }}
              >
                Register
              </Button>
            </div>
            <Button
              variant=""
              className="m-0 fw-semibold pt-3"
              onClick={() => {
                setIsOpen(false);
                navigate(-1);
              }}
            >
              Go Back
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
  return children;
};

export default RouteProtector;
