import React from "react";
import { useSelector } from "react-redux";

export const MaintenanceWrapper = ({ children }) => {
  const config = useSelector((x) => x.Config);

  if (config?.maintenance_mode)
    return (
      <>
        <div className="container">
          <div
            className="row justify-content-center align-items-center"
            style={{ height: "100vh" }}
          >
            <div className="col-12">
              <div className="w-100 d-flex flex-column align-items-center justify-content-center">
                <img
                  src="/assets/images/maintenance.webp"
                  alt=""
                  style={{ width: "30%", objectFit: "contain" }}
                  className="mb-4"
                />
                <h4 className="text-uppercase">Website Under Maintenance!</h4>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  else return children;
};
