import React from "react";
import { fetchOrders } from "../Services/OrderService";
import { toast } from "react-toastify";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { useNavigate } from "react-router-dom";
import { TitleHeader } from "../Components/TitleHeader";
import { useDispatch, useSelector } from "react-redux";

export const MyOrders = () => {
  const [loading, setLoading] = React.useState(false);
  const config = useSelector((x) => x.Config);
  const ongoingOrders = useSelector((x) => x.OngoingOrders);
  const [orders, setOrders] = React.useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  React.useEffect(() => {
    setLoading(true);
    fetchOrders({
      limit: config?.laundry_customer_maximum_order,
      offset: 1,
    }).then(
      (res) => {
        setLoading(false);
        dispatch({ type: "setOngoingOrders", payload: res.data });
      },
      (er) => {
        if (er && er.errors && er.errors.length > 0)
          er.errors.forEach((x) => toast.error(x.message));
        else toast.error("Something went wrong while fetching Ongoing Orders!");
      }
    );
  }, [config?.laundry_customer_maximum_order]);
  React.useEffect(() => {
    setOrders(ongoingOrders);
  }, [ongoingOrders]);
  return (
    <>
      <TitleHeader
        title="Ongoing Orders"
        subtitle="Below are your Ongoing Orders"
      />
      <div className="container">
        <div
          className="row flex-column align-items-center py-3"
          style={{ minHeight: "100vh" }}
        >
          {loading ? (
            <div className="col-12 text-center">
              <Spinner variant="primary" />
            </div>
          ) : orders.length > 0 ? (
            orders.map((x) => (
              <div className="col-lg-6 col-md-9 col-12 py-1">
                <div
                  className="card cursor-pointer"
                  onClick={() => navigate(`/order-details?orderId=${x.id}`)}
                >
                  <div className="card-body">
                    <div className=" d-flex">
                      <img
                        src="/assets/images/deliverybox.png"
                        className="d-none d-md-block"
                        alt=""
                      />
                      <div className="ms-4">
                        <h5 className="d-flex align-items-center justify-content-between mb-3">
                          {x.id}
                          <span className="text-primary fw-semibold w-100 text-end d-block">
                            RS. {x.order_amount}
                          </span>
                        </h5>
                        <div className="d-flex align-items-center flex-wrap flex-md-nowrap row-gap-3 column-gap-5">
                          <div className="col-12 col-md-auto">
                            <h6 className="mb-0 fw-bold">{x.pickup_time}</h6>
                            <span className="fw-semibold text-muted">
                              {x.pickup_date}
                            </span>
                          </div>
                          <div className="d-flex align-items-center col-md-auto mx-3 d-none">
                            <div
                              className="fa-solid fa-circle-dot text-primary"
                              style={{ fontSize: "12px" }}
                            ></div>
                            <hr
                              style={{ width: "30px", borderStyle: "dashed" }}
                              className="mx-1"
                            />
                            <div
                              className="fa-solid fa-circle text-primary"
                              style={{ fontSize: "12px" }}
                            ></div>
                          </div>
                          <div className="col-12  col-md-auto">
                            <h6 className="mb-0 fw-bold">
                              {x.order_delivery_time}
                            </h6>
                            <span className="fw-semibold text-muted">
                              {x.delivery_date}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="col-12 text-center">
              <h4 className="text-muted text-uppercase">No Records Found!</h4>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
