import React from "react";
import { Route, Routes } from "react-router-dom";
import { AppWrapper } from "../Components/AppWrapper";
import { ForgotPassword } from "../Pages/Auth/ForgotPassword";
import { Login } from "../Pages/Auth/Login";
import { NewPassword } from "../Pages/Auth/NewPassword";
import { PasswordOTP } from "../Pages/Auth/PasswordOTP";
import { PhoneOTP } from "../Pages/Auth/PhoneOTP";
import { SignUp } from "../Pages/Auth/SignUp";
import { BookAnAppointment } from "../Pages/BookAnAppointment";
import { Cart } from "../Pages/Cart";
import { Home } from "../Pages/Home";
import LaundryService from "../Pages/LaundryService";
import { MyOrders } from "../Pages/MyOrders";
import { Notifications } from "../Pages/Notifications";
import { OrderPlaced } from "../Pages/OrderPlaced";
import { OrderReview } from "../Pages/OrderReview";
import { OrdersHistory } from "../Pages/OrdersHistory";
import { PaymentFail } from "../Pages/PaymentFail";
import { PaymentSuccess } from "../Pages/PaymentSuccess";
import { Profile } from "../Pages/Profile";
import { Summary } from "../Pages/Summary";
import RouteProtector from "./RouteProtector";

export const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<AppWrapper />}>
        <Route path="" index element={<Home />} />
        <Route path="services" element={<LaundryService />} />
        <Route
          path="book-an-appointment"
          element={<RouteProtector children={<BookAnAppointment />} />}
        />
        <Route path="cart" element={<Cart />} />
        <Route
          path="summary"
          element={<RouteProtector children={<Summary />} />}
        />
        <Route
          path="order-review"
          element={<RouteProtector children={<OrderReview />} />}
        />
        <Route
          path="order-placed"
          element={
            <RouteProtector
              children={
                <OrderPlaced
                  title="Order Placed"
                  subtitle="Your pickup is confirmed."
                />
              }
            />
          }
        />
        <Route
          path="order-details"
          element={
            <RouteProtector
              children={
                <OrderPlaced
                  title="Order Details"
                  subtitle="Below are your order details."
                />
              }
            />
          }
        />
        <Route
          path="notifications"
          element={<RouteProtector children={<Notifications />} />}
        />
        <Route
          path="ongoing-orders"
          element={<RouteProtector children={<MyOrders />} />}
        />
        <Route
          path="orders-history"
          element={<RouteProtector children={<OrdersHistory />} />}
        />
        <Route
          path="profile"
          element={<RouteProtector children={<Profile />} />}
        />
        <Route
          path="payment-successful"
          element={<RouteProtector children={<PaymentSuccess />} />}
        />
        <Route
          path="payment-failed"
          element={<RouteProtector children={<PaymentFail />} />}
        />
      </Route>
      <Route path="/login" element={<Login />} />
      <Route path="/sign-up" element={<SignUp />} />
      <Route path="/phone-verification" element={<PhoneOTP />} />
      <Route path="/password-verification" element={<PasswordOTP />} />
      <Route path="/reset-password" element={<NewPassword />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
    </Routes>
  );
};
