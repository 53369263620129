import { Axios } from "./Axios";

export const fetchServices = async (limit, offset) => {
  return await Axios.get(`/services/list?limit=${limit}&offset=${offset}`);
};
export const fetchCategories = async () => {
  return await Axios.get(`/laundry-item-category/list`);
};
export const fetchLaundryItem = async ({
  serviceId,
  categoryId,
  limit,
  offset,
}) => {
  return await Axios.get(
    `/laundry-item/list/service/${serviceId}/category/${categoryId}?limit=${limit}&offset=${offset}`
  );
};
export const fetchDeliveryType = async (limit, offset) => {
  return await Axios.get(
    `/laundry-delivery-type/list?limit=${limit}&offset=${offset}`
  );
};
export const createOrder = async (payload) => {
  return await Axios.post("/customer/laundry/order/submit", payload, {
    responseType: "json",
  });
};
