import React from "react";
import GoogleMapComponent from "../Components/GoogleMapComponent";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

export const ZonePicker = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handlePicker = (e) => {
    console.log(e);
    dispatch({ type: "setZone", payload: e.zone });
    dispatch({
      type: "setAddress",
      payload: { address: e.address, cords: e.cords },
    });
    navigate("/");
  };
  return (
    <div className="container-fluid">
      <div
        className="row justify-content-center align-items-center flex-column"
        style={{ height: "100vh" }}
      >
        <img
          src="/assets/images/logo.png"
          style={{ height: "55px", width: "auto" }}
          className="mb-1"
          alt=""
        />
        <h5 className="text-center">Please select your location...</h5>
        <div
          className="col-lg-6 col-md-8 col-12"
          style={{ height: "calc(100vh - 120px)" }}
        >
          <GoogleMapComponent handleSelect={handlePicker} />
        </div>
      </div>
    </div>
  );
};
