import { addDays, eachDayOfInterval, format } from "date-fns";
import React from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AddressPicker } from "../Components/AddressPicker";
import { ConfirmationModal } from "../Components/ConfirmationModal";
import { DatePicker } from "../Components/DatePicker";
import useCartTotal from "../Hooks/useCartTotal";
import { createOrder, fetchDeliveryType } from "../Services/CartService";
import { fetchCoupon } from "../Services/OrderService";
import { Spinner } from "react-bootstrap";

export const BookAnAppointment = () => {
  const [amount, count] = useCartTotal();
  const config = useSelector((x) => x.Config);
  const orderType = useSelector((x) => x.OrderType);
  const cart = useSelector((x) => x.Cart);
  const coupon = useSelector((x) => x.Coupon);
  const pickupDate = useSelector((x) => x.PickupDate);
  const deliveryDate = useSelector((x) => x.DeliveryDate);
  const zone = useSelector((x) => x.Zone);
  const addresses = useSelector((x) => x.Addresses);
  const navigate = useNavigate();
  const [submitting, setSubmitting] = React.useState(false);
  const dispatch = useDispatch();

  const [loading, setLoading] = React.useState(false);
  const [deliveryType, setDeliveryType] = React.useState(null);
  const [showPickup, setShowPickup] = React.useState(false);
  const [showDelivery, setShowDelivery] = React.useState(false);
  const [pickupRange, setPickupRange] = React.useState([]);
  const [sPickup, setSPickup] = React.useState(null);
  const [sPSlot, setSPSlot] = React.useState(null);
  const [deliveryRange, setDeliveryRange] = React.useState([]);
  const [sDelivery, setSDelivery] = React.useState(null);
  const [sDSlot, setSDSlot] = React.useState(null);
  const [addressModal, setAddressModal] = React.useState(null);
  const [address, setAddress] = React.useState({
    pickup: null,
    delivery: null,
  });
  const [loaded, setLoaded] = React.useState(false);
  const [tAddress, setTAddress] = React.useState(null);
  const [note, setNote] = React.useState("");

  React.useEffect(() => {
    setLoading(true);
    fetchDeliveryType(50, 1).then(
      (res) => {
        setLoading(false);
        setDeliveryType(
          res.find((x) => x.title?.trim()?.toLowerCase() === orderType)
        );
      },
      (er) => {
        console.warn(er, "delivery types failed");
      }
    );
  }, [cart]);
  React.useEffect(() => {
    const sd = addDays(new Date(), 1);
    const ed = addDays(sd, 14);
    const dif = eachDayOfInterval({ start: sd, end: ed });
    if (pickupDate?.date) {
      setSPickup(pickupDate?.date);
    }
    if (pickupDate?.slot) {
      setSPSlot(pickupDate?.slot);
    }
    setPickupRange(dif);
  }, [pickupDate]);
  React.useEffect(() => {
    if (sPickup && deliveryType) {
      console.log(deliveryType);

      const sd = addDays(sPickup, deliveryType?.duration);
      const ed = addDays(sd, 14);
      const dif = eachDayOfInterval({ start: sd, end: ed });
      if (deliveryDate?.date) {
        setSDelivery(deliveryDate?.date);
      }
      if (deliveryDate?.slot) {
        setSDSlot(deliveryDate?.slot);
      }
      setDeliveryRange(dif);
    }
  }, [sPickup, deliveryType, deliveryDate]);
  React.useEffect(() => {
    if (address.delivery || address.pickup) {
      dispatch({ type: "setAddresses", payload: address });
    }
  }, [address]);
  React.useEffect(() => {
    if (!loaded) {
      if (addresses) {
        setAddress(addresses);
      }
      setLoaded(true);
    }
  }, [addresses, loaded]);

  const HandlePickupChange = (e) => {
    setSPickup(e.date);
    setSPSlot(e.slot);
    dispatch({ type: "setPickupDate", payload: e });
    setShowPickup(false);
  };
  const HandleDeliveryChange = (e) => {
    setSDelivery(e.date);
    setSDSlot(e.slot);
    dispatch({ type: "setDeliveryDate", payload: e });
    setShowDelivery(false);
  };

  const HandleAddressChange = (e) => {
    if (e.laundry_zone_id == zone) {
      if (addressModal === "pickup") {
        setAddress((v) => ({ ...v, pickup: e }));
      } else {
        setAddress((v) => ({ ...v, delivery: e }));
      }
      setAddressModal(null);
    } else {
      setTAddress(e);
    }
  };
  const ReplaceAddress = (e) => {
    if (addressModal === "pickup") {
      setAddress((v) => ({ pickup: e, delivery: null }));
    } else {
      setAddress((v) => ({ pickup: null, delivery: e }));
    }
    setAddressModal(null);
    setTAddress(null);

    dispatch({ type: "setZone", payload: e.laundry_zone_id });
    dispatch({ type: "setCart", payload: [] });
    dispatch({ type: "setOrderType", payload: null });
    dispatch({ type: "setService", payload: null });
    dispatch({ type: "setCategory", payload: null });
  };

  const placeOrder = () => {
    if (!loading) {
      setSubmitting(true);
      const payload = {
        payment_status: "unpaid",
        delivery_date: format(deliveryDate?.date, "yyyy-MM-dd HH:mm:ss.SSS"),
        pickup_date: format(pickupDate?.date, "yyyy-MM-dd HH:mm:ss.SSS"),
        payment_method: "cash_on_delivery",
        platform: 1,
        pickup_coordinates: {
          latitude: address?.pickup?.latitude,
          longitude: address?.pickup?.longitude,
        },
        destination_coordinates: {
          latitude: address?.delivery?.latitude,
          longitude: address?.delivery?.longitude,
        },
        pickup_address: {
          floor: null,
          road: address?.pickup?.streetNo,
          house: address?.pickup?.houseNo,
          address: address?.pickup?.address,
          contact_person_name: address?.pickup?.contact_person_name,
          contact_person_number: address?.pickup?.contact_person_number,
        },
        destination_address: {
          floor: null,
          road: address?.delivery?.streetNo,
          house: address?.delivery?.houseNo,
          address: address?.delivery?.address,
          contact_person_name: address?.delivery?.contact_person_name,
          contact_person_number: address?.delivery?.contact_person_number,
        },
        bar_code: "",
        order_pickup_time_slot_id: pickupDate?.slot?.id,
        order_type_id: deliveryType?.id,
        order_delivery_time_slot_id: deliveryDate?.slot?.id,
        laundry_delivery_type_id: deliveryType?.id,
        service_id: null,
        category_id: null,
        delivery_charge: 0,
        pickup_schedule_at: format(pickupDate?.date, "yyyy-MM-dd HH:mm:ss.SSS"),
        delivery_schedule_at: format(
          deliveryDate?.date,
          "yyyy-MM-dd HH:mm:ss.SSS"
        ),
        pending: "",
        distance: 10.5,
        note,
        order_pickup_time_slot_date: format(
          pickupDate?.date,
          "yyyy-MM-dd HH:mm:ss.SSS"
        ),
        order_delivery_time_slot_date: format(
          deliveryDate?.date,
          "yyyy-MM-dd HH:mm:ss.SSS"
        ),
        laundryZoneId: zone,
        coupon_code: coupon?.code,
        order_pickup_time_slot: `${pickupDate?.slot?.start_time} - ${pickupDate?.slot?.end_time}`,
        order_delivery_time_slot: `${deliveryDate?.slot?.start_time} - ${deliveryDate?.slot?.end_time}`,
        cart: [],
        tax_amount: config.laundry_tax,
        order_amount:
          amount + amount * (config.laundry_tax / 100) + deliveryType?.charge,
      };
      const afterOrder = (order) => {
        setSubmitting(false);
        console.log(order);
        navigate(`/order-placed?orderId=${order?.order_id}`, {
          replace: true,
        });
        dispatch({ type: "setCart", payload: [] });
        dispatch({ type: "setOrderType", payload: null });
        dispatch({ type: "setService", payload: null });
        dispatch({ type: "setCategory", payload: null });
        dispatch({ type: "setDeliveryDate", payload: null });
        dispatch({ type: "setPickupDate", payload: null });
        dispatch({ type: "setCoupon", payload: null });
        dispatch({ type: "setAddresses", payload: null });
        dispatch({ type: "setNote", payload: null });
      };
      createOrder(payload).then(
        (res) => {
          afterOrder(res);
        },
        (er) => {
          setSubmitting(false);
          if (er && er.errors && er.errors.length !== 0) {
            console.log(er);
            er.errors.forEach((x) => {
              toast.error(x.message);
            });
          }
        }
      );
    }
  };

  return (
    <>
      <div className="container">
        <div className="row justify-content-center py-4">
          <div className="col-xl-6 col-lg-7 col-md-10 col-12">
            <h3 className="mb-1 fw-bold" style={{ letterSpacing: "-1px" }}>
              Book an Appointment
            </h3>
            <hr />
            {loading ? (
              <div className="col-12 text-center">
                <Spinner variant="primary" />
              </div>
            ) : (
              <>
                {/* Address */}
                <div className="card  mb-3">
                  <div className="card-body">
                    <h5 className="fw-bold mb-3">Address</h5>
                    <div
                      className="row cursor-pointer"
                      onClick={() => setAddressModal("pickup")}
                    >
                      <div className="col-1 text-end pe-0">
                        <i
                          className="fa-solid fa-circle-dot"
                          style={{ color: "#32b6e9" }}
                        ></i>
                      </div>
                      <div className="col-11">
                        <h6 className="mb-1">Pickup Address</h6>
                        <p className="m-0">
                          {address.pickup
                            ? address.pickup?.address
                            : "Select address"}
                        </p>
                        <hr className="border-2" />
                      </div>
                    </div>

                    <div
                      className="row cursor-pointer"
                      onClick={() => setAddressModal("delivery")}
                    >
                      <div className="col-1 text-end pe-0">
                        <i
                          className="fa-solid fa-location-dot text-primary"
                          style={{ fontSize: "20px" }}
                        ></i>
                      </div>
                      <div className="col-11">
                        <h6 className="mb-1">Delivery Address</h6>
                        <p className="m-0">
                          {address.delivery
                            ? address.delivery?.address
                            : "Select address"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Date pickers */}
                <div className="card  mb-3">
                  <div className="card-body">
                    <h5 className="fw-bold">Schedule Dates</h5>

                    <div className="col-12 d-flex flex-wrap align-items-center justify-content-between px-4 border border-gray rounded position-relative mt-4">
                      <div className="col-5">
                        <small
                          style={{ position: "absolute", top: "-12px" }}
                          className="bg-white px-2"
                        >
                          Pickup
                        </small>
                        <div
                          className="d-flex align-items-center py-3 border-r border-gray cursor-pointer"
                          onClick={() => setShowPickup(true)}
                        >
                          <i
                            className="fa-regular fa-calendar-plus me-3"
                            style={{ fontSize: "22px", color: "#666" }}
                          ></i>
                          {sPickup ? format(sPickup, "eee, do MMM") : "Date"}
                          {sPSlot && sPickup ? <br /> : "/"}
                          {sPSlot
                            ? `${sPSlot?.start_time} - ${sPSlot?.end_time}`
                            : "Time"}
                        </div>
                      </div>
                      <div className="col-1 d-block d-sm-none border-start border-gray h-100 py-2 border-2">
                        &nbsp;
                      </div>
                      <div className="col-5 ">
                        <small
                          style={{ position: "absolute", top: "-12px" }}
                          className="bg-white px-2"
                        >
                          Delivery
                        </small>
                        <div
                          className="d-flex align-items-center py-3 cursor-pointer"
                          onClick={() => {
                            if (sPickup && sPSlot) setShowDelivery(true);
                            else
                              toast.error(
                                "Please select pickup date & slot first..."
                              );
                          }}
                        >
                          <i
                            className="fa-regular fa-calendar-plus me-3"
                            style={{ fontSize: "22px", color: "#666" }}
                          ></i>
                          {sDelivery
                            ? format(sDelivery, "eee, do MMM")
                            : "Date"}
                          {sDSlot && sDelivery ? <br /> : "/"}
                          {sDSlot
                            ? `${sDSlot?.start_time} - ${sDSlot?.end_time}`
                            : "Time"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Note */}
                <div className="card  mb-3">
                  <div className="card-body">
                    <h5 className="fw-bold mb-3">Appointment Note.</h5>
                    <textarea
                      className="form-control"
                      rows="3"
                      value={note || ""}
                      onChange={(e) => setNote(e.target.value)}
                    ></textarea>
                  </div>
                </div>
                {/* Next button */}
                <div className="card border-white shadow">
                  <div className="card-body">
                    {deliveryDate && pickupDate ? (
                      <button
                        className="btn btn-primary w-100 text-white fw-semibold"
                        disabled={loading || submitting}
                        onClick={() => {
                          placeOrder();
                        }}
                      >
                        {submitting ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="me-2"
                          />
                        ) : null}
                        <span className="fw-semibold">Book Appointment</span>
                      </button>
                    ) : (
                      <button
                        className="btn btn-primary w-100 text-white fw-semibold"
                        onClick={() =>
                          toast.error(
                            "Please select pickup & delivery date to continue..."
                          )
                        }
                      >
                        Book Appointment
                      </button>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {/* Pickup date modal*/}
      <Modal
        show={showPickup}
        backdrop="static"
        keyboard={false}
        centered
        onHide={() => setShowPickup(false)}
      >
        <Modal.Header closeButton className="py-2">
          <Modal.Title style={{ fontSize: "18px" }}>
            Choose Preferred Date / Time
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DatePicker
            range={pickupRange}
            selected={sPickup}
            onChange={HandlePickupChange}
            orderType={deliveryType?.id}
            timeSlot={sPSlot}
            type={1}
          />
        </Modal.Body>
      </Modal>
      {/* delivery date modal*/}
      <Modal
        show={showDelivery}
        backdrop="static"
        keyboard={false}
        centered
        onHide={() => setShowDelivery(false)}
      >
        <Modal.Header closeButton className="py-2">
          <Modal.Title style={{ fontSize: "18px" }}>
            Choose Preferred Date / Time
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DatePicker
            range={deliveryRange}
            selected={sDelivery}
            onChange={HandleDeliveryChange}
            orderType={deliveryType?.id}
            timeSlot={sDSlot}
            type={2}
          />
        </Modal.Body>
      </Modal>
      {/* Addresses modal*/}

      <AddressPicker
        show={Boolean(addressModal)}
        closeButton={true}
        onHide={() => setAddressModal(null)}
        title={` Select Your ${
          addressModal === "pickup" ? "Pickup " : "Delivery"
        } Address`}
        onSelect={HandleAddressChange}
      />
      <ConfirmationModal
        show={Boolean(tAddress)}
        text="The Address you selected is not in the same zone as you selected in start if you change your zone your cart will be empty. Are your sure you want to change your address?"
        onYes={() => ReplaceAddress(tAddress, true)}
        onNo={() => setTAddress(null)}
      />
    </>
  );
};
