import React from "react";

export const UpperNav = () => {
  return (
    <div
      className="container-fluid text-white app-bg-dark"
      style={{ paddingTop: "23px", paddingBottom: "23px" }}
    >
      <div className="row">
        <div className="col-md-6 text-center text-md-start upper-nav-text-start">
          <span style={{ fontSize: "15px" }} className="fw-semibold">
            Support Days: Entire Week, 9 AM - Midnight
          </span>
        </div>
        <div className="col-md-6 text-center text-md-end upper-nav-text-end ">
          <span style={{ fontSize: "15px" }} className="fw-semibold">
            <span className="app-text-primary" style={{ marginRight: "6px" }}>
              Call Us:
            </span>
            +92-111-524-246
          </span>
        </div>
      </div>
    </div>
  );
};
