import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useCartTotal from "../Hooks/useCartTotal";

const deliveryTypes = [
  {
    title: "Regular",
    icon: "/assets/svgs/icons/regular.svg",
    des: "Dry Cleaning, Washing, Ironing, Shoe Cleaning",
    id: "regular",
  },
  {
    title: "Express",
    icon: "/assets/svgs/icons/express.svg",
    des: "24 hours rapid laundry service",
    id: "express",
  },
  {
    title: "Book Order",
    icon: "/assets/svgs/icons/appointment.svg",
    des: "Let our experts handle it for you",
    id: "appointment",
  },
];
export const Home = () => {
  // const [typesLoading, setTypesLoading] = React.useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const lastOrderType = useSelector((x) => x.OrderType);
  const [amount, total] = useCartTotal();
  const [tempType, setTempType] = React.useState(null);

  let [isOpen, setIsOpen] = React.useState(false);
  const selectType = (e) => {
    if (lastOrderType && lastOrderType !== e.id && total !== 0) {
      setTempType(e);
      setIsOpen(true);
    } else {
      dispatch({ type: "setOrderType", payload: e.id });
      if (e.id === "appointment") navigate("/book-an-appointment");
      else navigate("/services");
    }
  };
  const clearCart = () => {
    dispatch({ type: "setOrderType", payload: tempType.id });
    dispatch({ type: "setCart", payload: [] });
    dispatch({ type: "setService", payload: null });
    dispatch({ type: "setCategory", payload: null });
    if (tempType.id === "appointment") navigate("/book-an-appointment");
    else navigate("/services");
  };
  return (
    <>
      <div className="container-fluid bg-white">
        {/* <div
          className="row justify-content-center align-items-center"
          style={{ height: "calc(100vh - 60px)" }}
        >
          <div className="col-12">
            <div className="row justify-content-center column-gap-4">
              {deliveryTypes.map((e) => (
                <div
                  className="card col-auto cursor-pointer"
                  onClick={() => selectType(e)}
                  key={e.id}
                >
                  <div className="card-body">
                    {e.title === "Appointment" ? "Count For Me" : e.title}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div> */}
        <div className="row">
          <div
            className="col-lg-6  d-none d-lg-block px-0"
            style={{ overflow: "hidden" }}
          >
            <img
              src="/assets/images/home-hero.png"
              alt=""
              className="place-order-hero-img"
              // style={{ objectFit: "cover" }}
            />
          </div>
          <div className="col-lg-6 col-12 app-bg-primary place-order-container">
            <div
              className="w-100"
              // style={{ padding: "110px 0px" }}
            >
              <h2 style={{ marginBottom: "22px", fontWeight: "bold" }}>
                Place Your Order
              </h2>
              <p
                style={{
                  marginBottom: "66px",
                  fontSize: "17px",
                  lineHeight: "34px",
                }}
              >
                Our online laundry service offers three convenient ways to place
                an order, tailored to meet your unique needs. Choose the option
                that suits you best and experience hassle-free laundry service
                at its finest.
              </p>
              <div className="order-type-container">
                {deliveryTypes.map((x, i) => (
                  <div
                    onClick={() => selectType(x)}
                    key={i}
                    className="order-type-card"
                    style={{}}
                  >
                    <img src={x.icon} alt="" style={{ marginRight: "26px" }} />
                    <div className="order-type-card-inner">
                      <h4
                        className="fw-semibold"
                        style={{ fontSize: "20px", color: "#060615" }}
                      >
                        {x.title}
                      </h4>
                      <span
                        style={{
                          fontSize: "18px",
                          fontWeight: "normal",
                          color: "#282828",
                          // textAlign: "center",
                        }}
                      >
                        {x.des}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div
            className="row justify-content-center"
            style={{ padding: "59px 0px" }}
          >
            <div style={{ border: "1px solid #E5E5E5" }}>
              <div
                className="row row-gap-5 "
                style={{ padding: "40px 0px 27px 0px" }}
              >
                <div className="col-md-4">
                  <div className="d-flex align-items-start">
                    <img
                      src="/assets/svgs/icons/laundry1.svg"
                      style={{
                        width: "52px",
                        height: "52px",
                        marginRight: "26px",
                      }}
                      alt=""
                    />
                    <div className="">
                      <h4 className="fw-bold" style={{ color: "#000" }}>
                        Save Time & Money
                      </h4>
                      <span style={{ fontSize: "15px", lineHeight: "34px" }}>
                        No more wasted time driving to laundromats, we pick and
                        deliver for free!
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="d-flex align-items-start">
                    <img
                      src="/assets/svgs/icons/laundry 2.svg"
                      alt=""
                      style={{
                        width: "52px",
                        height: "52px",
                        marginRight: "26px",
                      }}
                    />
                    <div className="">
                      <h4 className="fw-bold" style={{ color: "#000" }}>
                        Pay Online in Seconds
                      </h4>
                      <span style={{ fontSize: "15px", lineHeight: "34px" }}>
                        Manage your Press account and billing online from your
                        smartphone or computer.
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="d-flex align-items-start">
                    <img
                      src="/assets/svgs/icons/laundry1.svg"
                      alt=""
                      style={{
                        width: "52px",
                        height: "52px",
                        marginRight: "26px",
                      }}
                    />
                    <div className="">
                      <h4 className="fw-bold" style={{ color: "#000" }}>
                        Eco-Friendly
                      </h4>
                      <span style={{ fontSize: "15px", lineHeight: "34px" }}>
                        We use safe and clean perc-free solvents, so you, and
                        the earth can look good.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="row align-items-center"
          style={{ padding: "10px 0px 56px 0px" }}
        >
          <div className="col-md-5 px-0">
            <img
              src="/assets/images/img2 3.png"
              className="w-100"
              alt=""
              style={{ objectFit: "cover" }}
            />
          </div>
          <div className="col-md-7 download-app-text-container">
            <h4
              style={{ color: "#000", marginBottom: "23px" }}
              className="fw-bold"
            >
              Download Our App for Convenient Laundry Services!
            </h4>
            <p
              style={{
                fontSize: "14px",
                marginBottom: "25px",
                lineHeight: "34px",
              }}
            >
              For an even more convenient experience, we invite you to download
              our app from the App Store. With our app, you can place orders
              effortlessly, track the status of your laundry, and manage your
              account on the go. Enjoy the ease and flexibility of scheduling
              pickups, selecting services, and making payments, all from the
              palm of your hand.
              <br />
              Download our app today and make laundry day simpler and more
              efficient than ever before.
            </p>
            <div className="d-flex flex-wrap align-items-center">
              <img
                src="/assets/svgs/icons/App Store.svg"
                alt=""
                style={{ marginRight: "12px", maxWidth: "calc(50% - 6px)" }}
              />
              <img
                src="/assets/svgs/icons/Google Play.svg"
                alt=""
                style={{ maxWidth: "calc(50% - 6px)" }}
              />
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={isOpen}
        onHide={() => setIsOpen(false)}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton className="border-0">
          <Modal.Title className="text-primary">Warning...</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className="fw-normal text-center">
            You already have {total} item(s) in your cart.
          </h5>
        </Modal.Body>
        <Modal.Footer className="border-0 justify-content-between">
          <Button
            variant="primary"
            className="m-0 text-white fw-semibold"
            style={{ width: "49%" }}
            onClick={() => {
              setIsOpen(false);
              navigate("/cart");
            }}
          >
            View Cart
          </Button>
          <Button
            variant="primary"
            className="m-0 bg-dark text-white fw-semibold"
            style={{ width: "49%" }}
            onClick={() => {
              setIsOpen(false);
              clearCart();
            }}
          >
            Create New
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
