import React from "react";
import { deleteAddress, fetchAddresses } from "../Services/MainService";
import { toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import Card from "react-bootstrap/Card";
import { ConfirmationModal } from "./ConfirmationModal";
import { AddAddress } from "./AddAddress";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";

export const AddressPicker = ({
  onSelect,
  closeButton = false,
  show = false,
  onHide = null,
  title = "",
  titleStyle = {},
  editOnSelect = false,
}) => {
  const [loading, setLoading] = React.useState(false);
  const [address, setAddress] = React.useState([]);
  const [atd, setATD] = React.useState(null);
  const [deleting, setDeleting] = React.useState(false);
  const [showAdd, setShowAdd] = React.useState(false);
  const zone = useSelector((x) => x.Zone);
  const [addy, setAddy] = React.useState(null);

  React.useEffect(() => {
    setLoading(true);
    fetchAddresses().then(
      (res) => {
        setLoading(false);
        setAddress(res);
      },
      (er) => {
        toast.error("Something wert wrong while fetching your addresses...");
      }
    );
  }, []);

  const DeleteAddress = () => {
    if (atd) {
      setDeleting(true);
      deleteAddress(atd.id).then(
        () => {
          setAddress((x) => x.filter((y) => y.id !== atd.id));
          toast.success("Address Deleted Successfully...");
          setDeleting(false);
          setATD(null);
        },
        () => {
          setDeleting(false);
          setATD(null);
          toast.error("Something went wrong while deleting the address...");
        }
      );
    } else {
      setATD(null);
    }
  };
  const handleAddAddress = (e) => {
    console.log(e, "handleAddAddress");
    setAddress((x) => {
      const ary = [...x];
      const i = ary.findIndex((y) => y.id === e.id);
      if (~i) {
        ary[i] = e;
      } else ary.push(e);
      return ary;
    });

    setShowAdd(false);
  };
  const handleSelect = (e) => {
    const payload = {
      ...e,
      zone_id: e.zone_id || e.zoneId,
      laundry_zone_id: e.laundry_zone_id || e.laundryZoneId,
    };
    if (onSelect) {
      onSelect(payload);
    }
    if (editOnSelect) {
      handleEdit(payload);
    }
  };
  const handleEdit = (x) => {
    const payload = {
      ...x,
      zone_id: x.zone_id || x.zoneId,
      laundry_zone_id: x.laundry_zone_id || x.laundryZoneId,
      zoneId: x.zoneId || x.zone_id,
      laundryZoneId: x.laundryZoneId || x.laundry_zone_id,
      cords: {
        lat: parseFloat(x.latitude), // Default latitude (e.g., Karachi)
        lng: parseFloat(x.longitude), // Default longitude (e.g., Karachi)
      },
    };
    setAddy(payload);
    setShowAdd(true);
  };
  return (
    <>
      <Modal
        show={show}
        backdrop="static"
        keyboard={false}
        centered
        onHide={() => {
          if (onHide) onHide();
        }}
      >
        <Modal.Header closeButton={closeButton} className="py-2">
          <Modal.Title style={{ ...titleStyle, fontSize: "18px" }}>
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading ? (
            <div className="col-12 text-center">
              <Spinner size="lg" variant="primary" />
            </div>
          ) : address.length !== 0 ? (
            address.map((x, i) => (
              <Card
                className={`${i !== address.length - 1 ? "mb-2" : ""}`}
                key={i}
              >
                <Card.Body>
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <p
                      className="text-capitalize fw-semibold mb-0 cursor-pointer"
                      style={{ fontSize: "19px" }}
                      onClick={() => handleSelect(x)}
                    >
                      {x.address_type}
                    </p>
                    <div>
                      <span
                        className="cursor-pointer text-secondary me-2"
                        onClick={() => handleEdit(x)}
                      >
                        <i className="fa-solid fa-pencil "></i>
                      </span>
                      <span
                        className="cursor-pointer text-danger me-2"
                        onClick={() => setATD(x)}
                      >
                        <i className="fa-solid fa-trash-alt"></i>
                      </span>
                    </div>
                  </div>
                  <span
                    className="hide-extra-text cursor-pointer"
                    style={{ fontSize: "16px" }}
                    onClick={() => handleSelect(x)}
                  >
                    {x.address}
                  </span>
                </Card.Body>
              </Card>
            ))
          ) : (
            <>
              <div className="col-12 text-center text-muted">
                No addresses found...
              </div>
            </>
          )}
          <button
            className="btn btn-primary w-100 fw-semibold text-white mt-3"
            onClick={() => setShowAdd(true)}
          >
            Add New Address
          </button>
          <ConfirmationModal
            show={Boolean(atd)}
            text="Are your sure you want to delete this address?"
            onYes={() => DeleteAddress()}
            onNo={() => setATD(null)}
            yesLoading={deleting}
          />
          <AddAddress
            show={showAdd}
            onHide={() => setShowAdd(false)}
            onAdd={handleAddAddress}
            addy={addy}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
