import axios from "axios";

export const baseUrl = process.env.REACT_APP_BASE_URL;
export const Axios = axios.create({
  baseURL: `${baseUrl}/api`,
  headers: {
    "Content-Type": "application/json",
  },
});
Axios.interceptors.request.use((config) => {
  const token = localStorage.getItem("token");

  config.headers.Accept = "application/json";
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  config.headers.Laundryzoneid = sessionStorage.getItem("zone");
  return config;
});

Axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (err) => {
    return Promise.reject(err.response.data);
  }
);
