import React, { useEffect, useCallback } from "react";

export const AddonCounter = ({ value = 0, onChange, max = 0 }) => {
  const handleDecrement = useCallback(() => {
    if (value > 0) {
      const newCount = value - 1;
      if (onChange) onChange(newCount);
    }
  }, [value, onChange]);

  const handleIncrement = useCallback(() => {
    if (value < max) {
      const newCount = value + 1;
      if (onChange) onChange(newCount);
    }
  }, [value, onChange, max]);

  useEffect(() => {
    if (value > max) {
      handleDecrement();
    }
  }, [value, max, handleDecrement]);

  return (
    <div className="input-group" style={{ width: "100px" }}>
      <button
        className="counter-btn"
        type="button"
        id="button-addon1"
        disabled={value === 0}
        onClick={handleDecrement}
      >
        <i className="fa-solid fa-minus"></i>
      </button>
      <div
        type="text"
        className="text-center border-0 d-flex align-items-center"
        style={{ width: "fit-content", padding: "0px 16px" }}
        placeholder=""
        aria-label="Example text with button addon"
        aria-describedby="button-addon1"
        disabled
      >
        {value}
      </div>
      <button
        className="counter-btn add"
        type="button"
        id="button-addon2"
        onClick={handleIncrement}
        disabled={value === max}
      >
        <i className="fa-solid fa-plus"></i>
      </button>
    </div>
  );
};
